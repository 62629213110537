import StageManager from "./StageManager";
import {useState, useEffect, useContext} from "react";
import {getMassResultsColumns, mapDataFunction, operationMarkup as oms, operations as ops, useColumns} from "./Shared";
import {DatePicker, Col, Row, Select, Form, Space, ConfigProvider, Button} from "antd";
import api, {getBearerAuthHeader} from "../../lib/util";
import {PermissionsContext} from "../../lib/PermissionsContext";
import locale from 'antd/lib/locale/ru_RU';
import dayjs from "dayjs";
import {NotifyError} from "../../lib/notify";


export default function Archive() {

    const [selectedRows, setSelectedRows] = useState([]);
    const [stages, setStages] = useState([]);
    const [selectedStages, setSelectedStages] = useState([]);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [total, setTotal] = useState();

    const urlParams = new URLSearchParams(window.location.search);
    const full_sequence = urlParams.get('select') || null;
    const [queryParams, setQueryParams] = useState({});
    const [tempSelectedStage, setTempSelectedStage] = useState([]);
    const [dateFilter, setDateFilter] = useState(null);
    const [stageFilter, setStageFilter] = useState(null);


    const {hasPermission} = useContext(PermissionsContext);
    const canResynth = hasPermission('synth.re-synth');

    const extraColumns = [{
        title: 'Текущий этап',
        dataIndex: 'current_stage_name',
        key: 'current_stage_name',
        width: 150,
        after: 'date',
        fixed: 'left',
    }];

    const {columns, filters} = useColumns(true, true, true, extraColumns);
    const restColumns = columns.concat(getMassResultsColumns());


    const removeExtraColumns = (columnNames) => {
        return restColumns.filter((column) => {
            return !columnNames.includes(column.dataIndex);
        });
    }

    function getOperations() {
        return [
            // [oms[ops.EXTRASYNTHESIS]]
        ];
    }

    const loadStages = () => {
        api.getJSON('/api/stage/filter-list').then(resp => {
            if (resp) {
                const options = resp.map((item) => {
                    return {label: item.label, value: item.id};
                });
                setStages(options);
            }
        });
    }

    useEffect(() => {
        loadStages();
    }, []);

    const getQueryParams = () => {
        const params = {};
        if (stageFilter) {
            params['stage'] = [...stageFilter];
        }
        if (dateFilter) {
            params['date_from'] = dateFilter[0].format('YYYY-MM-DD');
            params['date_to'] = dateFilter[1].format('YYYY-MM-DD');
        }
        params['page'] = page;
        params['pageSize'] = pageSize;
        return params;
    }

    useEffect(() => {
        setQueryParams(getQueryParams());
    }, [selectedStages, page, pageSize, dateFilter, stageFilter]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setSelectedStages(tempSelectedStage);
        }, 1000);
        return () => clearTimeout(timeout);
    }, [tempSelectedStage]);

    const mapFunction = (rawData) => {
        const {data, meta} = rawData;
        const mapped = mapDataFunction(data);
        setTotal(meta.total);
        return mapped;
    }

    const exportExcel = () => {
        let params = {};
        if (selectedRows.length > 0) {
            params['ids'] = selectedRows;
        } else {
            NotifyError('Выберите пробирки для экспорта');
            return;
        }


        // не переделывать в api.postJSON!
        const header = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({...params}),
        };
        header.headers = {
            ...header.headers,
            ...getBearerAuthHeader(),
        }

        fetch('/api/archive/export', header).then(response => {
            response.blob().then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                const startDate = dateFilter ? dayjs(dateFilter[0]).format('YYYY-MM-DD') : 'all';
                const endDate = dateFilter ? dayjs(dateFilter[1]).format('YYYY-MM-DD') : 'all';
                if (startDate === endDate) {
                    a.download = `archive.xlsx`;
                } else {
                    a.download = `archive-${startDate}-${endDate}.xlsx`;
                }
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
        });
    }

    function getFilters() {
        return (
            <Row>
                <Col span={12}>
                    <Space>
                        <label>Диапазон</label>
                        <ConfigProvider locale={locale}>
                            <DatePicker.RangePicker onChange={(value) => {
                                setDateFilter(value);
                            }} value={dateFilter} placeholder={["Начало", "Конец"]}
                            />
                        </ConfigProvider>
                        <label>Этап:</label>
                        <Select style={{minWidth: 200}} onChange={(e) => {
                            setStageFilter(e)
                        }} options={stages} value={stageFilter} mode={'multiple'} />
                        <Button onClick={() => {
                            setStageFilter(null);
                            setDateFilter(null);
                        }}>Сбросить</Button>
                    </Space>
                </Col>
                <Col span={12} style={{justifyContent: "flex-end", display: "flex"}}>
                    <Button onClick={exportExcel}>Экспорт в Excel</Button>
                </Col>
            </Row>
        );
    }

    // post /api/archive/export



    return <>
        <StageManager
            title={'Архив'}
            operationButtons={getOperations()}
            columns={removeExtraColumns(['current_stage_name', 'stage_comment'])}
            mapFucntion={mapFunction}
            url={'/api/archive'}
            queryParams={queryParams}
            filter={getFilters}
            selectedRows={selectedRows}
            initialSearchString={full_sequence}
            pagination={{
                pageSizeOptions: ['50', '100', '250', '500', '1000', '2000', '3000'],
                pageSize: pageSize,
                total: total,
                defaultPageSize: pageSize,
                onChange: (page, pageSize) => {
                    setPageSize(pageSize);
                }
            }}
            extraFilter={getFilters}
            // dataFilter={getFilters}
            onSelectionChange={(rows) => {
                setSelectedRows(rows.map((item) => item.id));
            }}
            onDataTableChange={(pagination, filters, sorter, extra) => {
                setPage(pagination.current);
            }}
            canEdit={canResynth}
            showLabelsBtn={false}
        /></>;

}
