import React, {useEffect, useMemo, useState} from 'react';
import {Button, Col, Divider, Empty, Form, Input, Row, Select, Typography} from 'antd';
import PageHeader from "../../components/mock/PageHeader";

import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Places from "./Places";
import {api, format} from "../../lib/util";
import {synthTypesLabels} from "../Stages/Operations/Resynth";
import dayjs from "dayjs";
import CTable from "../../components/CTable";

const {Search} = Input;
const {Text} = Typography;
const {Option, OptGroup} = Select;

export const PlaceList = (props) => {
    const [search, setSearch] = useState('');
    const [flag, setFlag] = useState(false);
    const [error, setError] = useState(null);
    const [sort, setSort] = useState({});

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [total, setTotal] = useState(0);

    const [isOligLoading, setIsOligLoading] = useState(false);
    const [isDeviceLoading, setIsDeviceLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [device, setDevice] = useState({});
    const [selectedIds, setSelectedIds] = useState([]);
    const [draggableId, setDraggableId] = useState(0);

    const [types, setTypes] = useState([]);
    const [filterTypes, setFilterTypes] = useState([]);

    const cycleId = parseInt(props.match.params.cycle_id);

    const loadOligs = () => {
        setIsOligLoading(true);
        let params = [];
        let url = `/api/oligonucleotid/planned`;
        if (filterTypes) {
            filterTypes.map((type) => params.push(`filter[types][]=${type}`));
        }
        if (search) {
            params.push(`search=${search}`);
        }
        if (sort.field) {
            params.push(`sortField=${sort.field}`);
        }
        if (sort.order) {
            params.push(`sortOrder=${sort.order}`);
        }
        params.push('planned=' + (flag ? 1 : 0));
        params.push('page=' + page);
        params.push('pageSize=' + pageSize);

        url += '?' + params.join('&');
        api.getJSON(url).then((result) => {
            const {data, meta} = result;
            data.forEach((item) => {
                item.oligonucleotid.orders = item.oligonucleotid.orders.filter((order, index, self) =>
                    index === self.findIndex((t) => (t.id === order.id)));
            });
            setItems(data);
            setTotal(meta.total);
        }).catch((error) => {
            setError(error);
        }).finally(() => {
            setIsOligLoading(false);
        });
    }

    function loadDevices() {
        setIsDeviceLoading(true);
        let url = `/api/synthesis/${props.match.params.date}/${props.match.params.id}`;
        api.getJSON(url).then((result) => {
            setDevice(result);
        }).finally(() => {
            setIsDeviceLoading(false);
        });
    }

    function loadTypes() {
        api.getJSON('/api/oligonucleotide-type').then((result) => {
            setTypes(result);
        });
    }

    const getColumns = () => {
        return [
            {
                title: '№ заказа', dataIndex: 'index', key: 'index', width: 80, fixed: 'left',
                render: (val, record) => {
                    let numbers = record.oligonucleotid.orders.filter((item) => record.ngs === item.pivot.ngs)
                        .map((order) => order.order_number);
                    return numbers.map((number, index) => <Text key={index} id={index}>{number}<br/></Text>);
                }
            },
            {
                title: 'Клиент', dataIndex: 'client', key: 'owner', fixed: 'left',
                render: function (val, record) {
                    if (!record.oligonucleotid.orders || record.oligonucleotid.orders.length === 0) {
                        return '-';
                    }
                    let listItems = record.oligonucleotid.orders.map((item, index) => item.user.organization);
                    return listItems.map((item, index) => <Text key={index} id={index}>{item}<br/></Text>);
                },
                width: 200,
            },
            {
                title: 'Дата заказа',
                dataIndex: 'orderDate',
                key: 'orderDate', fixed: 'left',
                render: function (text, record) {
                    if (!record.oligonucleotid.orders || record.oligonucleotid.orders.length === 0) {
                        return <div/>
                    }
                    let listItems = record.oligonucleotid.orders.map((item, index) => dayjs(item.created_at).format('L'));
                    return listItems.map((item, index) => <Text key={index} id={index}>{item}<br/></Text>);
                },
                sorter: true,
                width: 120,
            },
            {
                title: 'Основание',
                dataIndex: 'reason',
                key: 'reason',
                width: 120, fixed: 'left',
                render: function (value) {
                    return synthTypesLabels[value];
                },
                sorter: true,
            },
            {
                title: 'Название',
                key: 'name',
                dataIndex: 'name',
                render: function (text, record) {
                    return record.oligonucleotid.name;
                },
                sorter: true,
                width: 110,
                fixed: 'left',
            },
            {
                title: 'НМОЛЬ', dataIndex: 'nanomole', key: 'nanomole', width: 100,
                render: (nmol, record) => {
                    const nmol_val = nmol ? Math.round(nmol * 100) / 100 : '-';
                    return format(nmol_val);
                }
            },
            {
                title: 'О.E.',
                key: 'quantity',
                dataIndex: 'quantity',
                render: v => format(v),
                width: 70,
            },
            {
                title: 'NGS',
                key: 'ngs',
                dataIndex: 'ngs',
                render: v => v ? 'Да' : 'Нет',
                width: 70,
            },
            {
                title: 'Последовательность',
                dataIndex: 'sequence',
                key: 'sequence',
                width: 200,
                render: function (text, record) {
                    return record.oligonucleotid.sequence;
                },
                sorter: true,
                ellipsis: true
            },
            {
                title: '5\'',
                dataIndex: 'modification_5',
                key: 'modification_5',
                render: function (text, record) {
                    return record.oligonucleotid.modification_5;
                },
                width: 100,
                sorter: true,
            },
            {
                title: '3\'',
                dataIndex: 'modification_3',
                render: function (text, record) {
                    return record.oligonucleotid.modification_3;
                },
                key: 'modification_3',
                width: 100,
                sorter: true,
            },
            {
                title: 'Тип',
                dataIndex: 'types',
                width: 100,
                sorter: true,
                render: function (text, record, index) {
                    const listItems = record.oligonucleotid.types0.map((item, index) => <div key={index}
                                                                                             id={index}>{item.name}</div>);
                    return listItems
                }
            },
            {
                title: 'Подтип',
                dataIndex: 'subtypes',
                width: 120,
                sorter: true,
                render: function (_, record, index) {
                    const listItems = record.oligonucleotid.types1.map((item, index) => <div key={index}
                                                                                             id={index}>{item.name}</div>);
                    return listItems
                }
            },
            {
                title: 'G',
                dataIndex: 'is_rich',
                sorter: true,
                width: 50,
                render: function (text, record, index) {
                    return <>{record.oligonucleotid.types2.length > 0 ? 'Да' : 'Нет'}</>
                }
            },
            {
                title: 'Длина',
                dataIndex: 'length',
                key: 'length',
                width: 60,
                sorter: true,
                render: function (text, record, index) {
                    return record.oligonucleotid.length;
                }
            }
        ];
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setSort(sorter);
        setSelectedIds([]);
        setPage(pagination.current);
    };

    const onBeforeCapture = (start) => {
        const draggableId = start.draggableId;

        setDraggableId(draggableId);
    };

    const isAcceptable = (place) => {

        if (place.places_count < (place.items.length + selectedIds.length)) {
            return false;
        }

        let total = 0;
        place.items.map(function (item) {
            total = total + item.count;
        });

        if (place.places_count <= total) {
            return false;
        }

        return true;
    }

    const getBackground = (snapshot, place) => {
        if (!snapshot.isDraggingOver) {
            return '#fff';
        }

        if (!isAcceptable(place)) {
            return 'rgb(241,255,225)';
        }

        return 'rgb(222, 235, 255)';
    }

    const getNewItems = (items, selectedIds, draggableId) => {
        items.forEach((item, iterator) => {
            item.iterator = iterator;
        });

        let newItems = items.filter(item => {
            if (selectedIds.length > 0 && selectedIds.indexOf(item.id) !== -1) {
                return true;
            }
            if (item.id === parseInt(draggableId)) {
                return true;
            }
            return false;
        });

        if (newItems.length === 0) {
            return;
        }

        return newItems;
    }


    const gatherParameters = (result, items, selectedIds, draggableId) => {
        const destination = result.destination;

        if (!destination || !draggableId) {
            return;
        }

        const newItems = getNewItems(items, selectedIds, draggableId);

        if (!newItems) {
            return;
        }

        const droppableId = destination.droppableId.split('_');
        const droppable_cycle = parseInt(droppableId[0]);
        const droppable_place = parseInt(droppableId[1]);

        return {newItems, droppable_cycle, droppable_place};
    }

    const getPlace = (device, droppable_cycle, droppable_place) => {
        let current_place = 0;
        device.places.map((cycle, cycle_index) => {
            if (cycle_index === droppable_cycle) {
                cycle.items.map(place => {
                    if (place.id === droppable_place) {
                        current_place = place;
                    }
                });
            }
        });

        if (!current_place || !isAcceptable(current_place)) {
            return false;
        }

        return current_place;
    }

    const updatePlaceItems = (device, current_place, newItems, cycle_id, place_id) => {
        device.places.map((cycle, cycle_index) => {
            if (cycle_index === cycle_id) {
                cycle.items.map(place => {
                    if (place.id === place_id) {
                        newItems.map((item, iterator) => {
                            if (place.items.length < place.places_count) {
                                let added = false;
                                place.items.forEach(function (oldItem) {
                                    if (oldItem.id === item.id) {
                                        oldItem.count++;
                                        added = true;
                                    }
                                });
                                if (!added) {
                                    place.items.push({
                                        id: item.id,
                                        iterator: (item.iterator + 1),
                                        name: item.oligonucleotid.name,
                                        count: 1,
                                        sequence: item.oligonucleotid.sequence,
                                        full_sequence: item.oligonucleotid.full_sequence,
                                        modification_5: item.oligonucleotid.modification_5,
                                        modification_3: item.oligonucleotid.modification_3,
                                    });
                                }
                            }
                        })

                        let place_count = 0;
                        place.items.map((item, iterator) => {
                            place_count = place_count + item.count;
                        })
                        place.total_count = place_count;

                    }
                })
            }
        })
    }

    const onDragEnd = (result) => {
        const params = gatherParameters(result, items, selectedIds, draggableId);
        if (!params) {
            return;
        }

        const place = getPlace(device, params.droppable_cycle, params.droppable_place);
        if (!place) {
            return false;
        }

        const newItems = getNewItems(items, selectedIds, draggableId);
        if (!newItems) {
            return false;
        }
        updatePlaceItems(device, place, newItems, params.droppable_cycle, params.droppable_place);
        setSelectedIds([]);
    }

    const wasMultiSelectKeyUsed = (e) => e.shiftKey;

    const wasToggleInSelectionGroupKeyUsed = (e) => {
        const isUsingWindows = navigator.platform.indexOf("Win") >= 0;
        return isUsingWindows ? e.ctrlKey : e.metaKey;
    };

    const multiSelectTo = (record_id) => {
        // Nothing already selected
        if (!selectedIds.length) {
            return [record_id];
        }

        const indexOfNew = items.findIndex(function (item) {
            return item.id === record_id
        });
        const indexOfLast = items.findIndex(function (item) {
            return item.id === selectedIds[selectedIds.length - 1]
        });

        if (indexOfNew === indexOfLast) {
            return [record_id];
        }

        const isSelectingForwards = indexOfNew > indexOfLast;
        const start = isSelectingForwards ? indexOfLast : indexOfNew;
        const end = isSelectingForwards ? indexOfNew : indexOfLast;
        const inBetween = items.slice(start, end + 1);

        let toAdd = [];
        inBetween.map(record => {
            // if already selected: then no need to select it again
            if (!selectedIds.includes(record.id)) {
                toAdd.push(record.id);
            }
        });

        const combined = [...selectedIds, ...toAdd];

        return combined;
    };

    const onClickRow = (e, record, index) => {
        // if (e.defaultPrevented) {
        //     return;
        // }
        //
        // if (e.button !== PRIMARY_BUTTON_NUMBER) {
        //     return;
        // }
        //
        // // marking the event as used
        // e.preventDefault();

        // if (wasMultiSelectKeyUsed(e)) {
        //     const updated = multiSelectTo(record.id);
        //     if (!updated) {
        //         setSelectedIds([record.id]);
        //     }
        //     setSelectedIds(updated);
        //     return;
        // }
        //
        // if (wasToggleInSelectionGroupKeyUsed(e)) {
        //     setSelectedIds([...selectedIds, record.id]);
        //     return;
        // }
        //
        //
        // setSelectedIds([record.id]);
    }

    const onTouchEndRow = (e, record) => {

    }

    const DroppableTableBody = ({...props}) => {
        return (
            <Droppable
                droppableId="ITEMS" isDropDisabled={true}
            >
                {(provided, snapshot) => (
                    <tbody
                        ref={provided.innerRef}
                        {...props}
                        {...provided.droppableProps}
                        className={`${props.className} ${
                            snapshot.isDraggingOver
                                ? "is-dragging-over"
                                : ""
                        }`}
                    ></tbody>
                )}

            </Droppable>
        );
    };

    const DraggableTableRow = ({index, record, items, ...props}) => {
        if (!items.length) {
            return (
                <tr className="ant-table-placeholder row-item" {...props} key={index}>
                    <td colSpan={getColumns().length} className="ant-table-cell">
                        <div className="ant-empty ant-empty-normal">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                        </div>
                    </td>
                </tr>
            );
        }

        const isSelected = selectedIds.some(
            (selectedId) => selectedId === record.id
        );
        const isGhosting =
            isSelected && Boolean(draggableId) && draggableId !== record.id;

        let isExistsInRight = false;
        if (record && device.places && device.places.length > 0) {
            isExistsInRight = device.places.some(function (places) {
                return places.items.some(function (place) {
                    return place.items.some(function (olig) {
                        return record.id === olig.id;
                    });
                });
            });
        }

        return (
            <Draggable
                key={props["data-row-key"]}
                draggableId={props["data-row-key"] ? props["data-row-key"].toString() : "1"}
                index={index}
            >
                {(provided, snapshot) => {
                    return (
                        <tr
                            ref={provided.innerRef}
                            {...props}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`row-item ${isSelected ? "row-selected" : ""} ${
                                isGhosting ? "row-ghosting" : ""
                            } ${snapshot.isDragging ? "row-dragging" : ""} ${isExistsInRight ? "row-exists" : ""}`}
                            // onClick={onClick}
                            // onTouchEnd={onTouchEnd}
                            // onKeyDown={event => onKeyDown(event, provided, snapshot)}
                        ></tr>
                    );
                }}
            </Draggable>
        );
    }

    const handleDelete = (cycle_index, place_id, element_id) => {
        device.places.map(function (cycle, index) {
            if (cycle_index === index) {
                cycle.items.map(function (place, index) {
                    if (place.id === place_id) {
                        place.items = place.items.filter(function (item, iterator) {
                            return item.id === element_id ? false : true;
                        })
                        let place_count = 0;
                        place.items.map(function (item, iterator) {
                            place_count = place_count + item.count;
                        })
                        place.total_count = place_count;
                    }
                });
            }
        })
        setDevice(device);
        setSelectedIds([]);
    }

    const onChangeItem = (cycleIndex, placeId, newItem) => {
        let newDevice = {...device};
        newDevice.places.map((cycle, cycle_index) => {
            if (cycle_index === cycleIndex) {
                cycle.items.map((place) => {
                    if (place.id === placeId) {
                        place.items.map((item, itemIndex) => {
                            if (item.id === newItem.id) {
                                place.items[itemIndex] = {...newItem};
                            }
                        });
                    }
                    place.total_count = place.items.reduce((total, item) => total + item.count, 0);
                });
            }
        });
        setDevice(newDevice);
    }

    const handleRecalc = (current_cycle, current_place, current_item, value) => {
        if (Number(value) <= 0) {
            setDevice(device);
            return false;
        }

        let total = 0;
        current_place.items.map(item => {
            if (item.id !== current_item.id) {
                total = total + item.count;
            }
        });

        if (total + Number(value) > current_place.places_count) {
            setDevice(device);
            return false;
        }


        device.places.map(function (cycle, cycle_index) {
            if (cycle_index === current_cycle) {
                cycle.items.map(function (place) {
                    if (place.id === current_place.id) {
                        let place_count = 0;
                        place.items.map(function (item, iterator) {
                            if (item.id === current_item.id) {
                                item.count = Number(value);
                            }

                            place_count = place_count + item.count;
                        })

                        place.total_count = place_count;
                    }
                })
            }
        })

        setDevice(device);
        setSelectedIds([]);

        return true;
    }

    const handleScaleChange = (value, current_cycle, current_id) => {
        device.places.map(function (cycle, cycle_index) {
            if (cycle_index === current_cycle) {
                cycle.items.map(function (place) {
                    if (place.id === current_id) {
                        place.scale = value;
                    }
                })
            }
        })
        setDevice(device);
        return true;
    }

    useEffect(() => {
        loadOligs();
    }, [search, flag, filterTypes, page, pageSize, sort]);

    useEffect(() => {
        loadDevices();
        loadTypes();
    }, []);

    const onChangeTypesFilter = function (values) {
        setFilterTypes(values);
    };

    const title = `План синтеза на ${dayjs(props.match.params.date).format('L')} ${device.name}`;

    function handleOnlyPlanned(flag) {
        setFlag(flag);
    }

    // За 10+ лет опыта в реакте первый раз использовал useMemo. Охренеть.
    const table = useMemo(() => {
        return <CTable columns={getColumns()}
                       tableName={'olig_synthesis'}
                       size={'small'}
                       rowKey="id"
                       dataSource={items}
                       pagination={{
                           showSizeChanger: true,
                           pageSizeOptions: ['50', '100', '250', '500', '1000', '2000', '3000'],
                           defaultPageSize: 50,
                           total: total,
                           pageSize: pageSize,
                           onChange: (page, pageSize) => {
                               setPageSize(pageSize);
                           }
                       }}
                       loading={isOligLoading}
                       rowSelection={{
                           selectedRowKeys: selectedIds,
                           onChange: (selectedRowKeys, selectedRows) => {
                               setSelectedIds(selectedRowKeys);
                           }
                       }}
                       components={{
                           body: {
                               // Custom tbody
                               wrapper: (val) =>
                                   DroppableTableBody({
                                       items: items,
                                       ...val
                                   }),
                               // Custom td
                               row: (val) =>
                                   DraggableTableRow({
                                       items,
                                       ...val
                                   })
                           }
                       }}
                       onRow={(record, index) => ({
                           index,
                           record,
                           onClick: (e) => onClickRow(e, record, index),
                           onTouchEnd: (e) => onTouchEndRow(e, record, index)
                       })}
                       onChange={handleTableChange}
                       scroll={{y: window.innerHeight - 300}}
        />;
    }, [items, isOligLoading, selectedIds, flag, search, filterTypes, page, pageSize, sort]);

    return (
        <>
            <PageHeader title={title} showFavorite={false}/>
            <div style={{background: '#fff', padding: '24px 24px 0px 24px'}}>
                <DragDropContext
                    onBeforeCapture={onBeforeCapture}
                    onDragEnd={onDragEnd}
                >
                    <Row className={`c-multi-drag-table ${draggableId ? "is-dragging" : ""}`}>
                        <Col span={17}>
                            <Row>
                                <Col span={10}>
                                    <Search placeholder="поиск" onSearch={(value) => {
                                        setSearch(value);
                                    }}/>
                                </Col>
                                <Col span={10} style={{marginLeft: 20}}>
                                    <Form.Item label={'Типы'}>
                                        <Select mode="tags" placeholder="Типы"
                                                onChange={onChangeTypesFilter}

                                        >
                                            <OptGroup label="Тип">
                                                {types && types.length > 0 && types.map((item, iterator) => {
                                                    if (item.type === 0) {
                                                        return <Option key={item.id}
                                                                       value={item.name}>{item.name}</Option>;
                                                    }
                                                })}
                                            </OptGroup>
                                            <OptGroup label="Подтип">
                                                {types && types.length > 0 && types.map((item, iterator) => {
                                                    if (item.type === 1) {
                                                        return <Option key={item.id}
                                                                       value={item.name}>{item.name}</Option>;
                                                    }
                                                })}
                                            </OptGroup>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{paddingBottom: 10}}>
                                <Col span={12}>
                                    <Button.Group>
                                        <Button
                                            type={!flag ? 'primary' : ''}
                                            onClick={() => handleOnlyPlanned(!flag)}
                                        >
                                            В ожидании
                                        </Button>
                                        <Button
                                            type={flag ? 'primary' : ''}
                                            onClick={() => handleOnlyPlanned(!flag)}
                                        >
                                            Спланированные
                                        </Button>
                                    </Button.Group>
                                </Col>
                            </Row>
                            <Row><Col span={24}>Выбрано: {selectedIds.length}</Col></Row>
                            {table}
                        </Col>
                        <Col span={1}>
                            <Divider type="vertical"/>
                        </Col>
                        <Col span={6} style={{paddingRight: 10}}>
                            <Places loading={isDeviceLoading} device={device} handleDelete={handleDelete}
                                    handleRecalc={handleRecalc} handleScaleChange={handleScaleChange}
                                    getBackground={getBackground} date={props.match.params.date}
                                    activeCycleIndex={cycleId}
                                    onChangeItem={onChangeItem}
                                    onAddBtnClick={(cycleIndex, placeId) => {
                                        const place = device.places[cycleIndex].find((place) => place.id === placeId);
                                        const newItems = selectedIds.map((id) => {
                                            const item = items.find((item) => item.id === id);
                                            return item;
                                        });
                                        updatePlaceItems(device, place, newItems, cycleIndex, placeId);
                                        setSelectedIds([]);
                                    }}
                            />
                        </Col>
                    </Row>
                </DragDropContext>
            </div>
        </>
    );
}

export default PlaceList;

