import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { Button, Form, Input, Select, Spin } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import api from '../../lib/util';
import FormattedInput from '../../components/FormattedInput';
import TextArea from 'antd/lib/input/TextArea';

const { Option } = Select;

const EditableRow = forwardRef(({ record, onSave, onCancel, form, activeColumn, ...props }, ref) => {
    const [isValidation, setIsValidation] = useState(false);
    let newOligValues = {};

    const inputRefs = {
        name: useRef(),
        full_sequence: useRef(),
        quantity: useRef(),
        ngs: useRef(),
        substance_type_id: useRef(),
        comment: useRef()
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 27) {
            onCancel();
        }
        if (e.keyCode === 13) {
            handleSave();
        }
    };

    useEffect(() => {
        newOligValues = {};
    }, []);

    useEffect(() => {
        if (activeColumn && inputRefs[activeColumn]?.current) {
            const input = inputRefs[activeColumn].current;
            input.focus();
        }
    }, [activeColumn]);

    const handleSave = async () => {
        try {
            const values = await form.validateFields();
            onSave({ ...record, ...values, ...newOligValues });
        } catch (error) {
            console.error('Validation failed:', error);
        }
    };

    const validateSequenceRemote = async (rule, value) => {
        if (!value) return Promise.resolve();
        if (record.full_sequence === value) return Promise.resolve();

        setIsValidation(true);
        try {
            const result = await api.postJSON('/api/oligonucleotid/validate', { sequence: value });
            if (result.errors) {
                setIsValidation(false);
                return Promise.reject(new Error(result.errors.sequence[0]));
            }
            newOligValues = {
                types: result.types,
                length: result.length,
            };
        } catch (e) {
            setIsValidation(false);
            return Promise.reject(new Error(e.errors?.sequence?.[0] || 'Ошибка при валидации последовательности'));
        }
        setIsValidation(false);
        return Promise.resolve();
    };

    const getColumnWidth = (columnName) => {
        return props.children[0].props.rowInfo.flattenColumns.find((column) => column.dataIndex === columnName)?.width || 'auto';
    };

    const validateNumber = (rule, value) => {
        if (value === '') return Promise.resolve();
        if (isNaN(value)) return Promise.reject('Введите число');
        return Promise.resolve();
    };

    return (
        <div {...props} ref={ref} className="ant-table-row editable-row">
            <div className="ant-table-cell ant-table-cell-fix-left" style={{ width: getColumnWidth('index') }}>
                {record.index}
            </div>
            <div className="ant-table-cell ant-table-cell-fix-left" style={{ width: getColumnWidth('name') }}>
                <Form.Item name="name" rules={[{ required: true, message: 'Введите наименование' }]} style={{ margin: 0 }}>
                    <Input ref={inputRefs.name} onKeyDown={onKeyDown} />
                </Form.Item>
            </div>
            <div className="ant-table-cell ant-table-cell-fix-left" style={{ width: getColumnWidth('types') }}>
                {record.types ? record.types.map((type) => type.name).join(', ') : '-'}
            </div>
            <div className="ant-table-cell ant-table-cell-fix-left" style={{ width: getColumnWidth('length') }}>
                {record.length}
            </div>
            <div className="ant-table-cell ant-table-cell-fix-left" style={{ width: getColumnWidth('full_sequence') }}>
                <Form.Item
                    name="full_sequence"
                    rules={[
                        { required: true, message: 'Введите последовательность' },
                        { validator: validateSequenceRemote }
                    ]}
                    style={{ margin: 0 }}
                    validateDebounce={300}
                >
                    <TextArea ref={inputRefs.full_sequence} onKeyDown={onKeyDown} rows={3} />
                </Form.Item>
            </div>
            <div className="ant-table-cell ant-table-cell-fix-left" style={{ width: getColumnWidth('modification_5') }}>
                {record.modification_5}
            </div>
            <div className="ant-table-cell ant-table-cell-fix-left" style={{ width: getColumnWidth('modification_3') }}>
                {record.modification_3}
            </div>
            <div className="ant-table-cell ant-table-cell-fix-left" style={{ width: getColumnWidth('quantity') }}>
                <Form.Item
                    name="quantity"
                    rules={[
                        { required: true, message: 'Введите количество' },
                        { validator: validateNumber }
                    ]}
                    style={{ margin: 0 }}
                >
                    <FormattedInput ref={inputRefs.quantity} value={record.quantity} onKeyDown={onKeyDown} />
                </Form.Item>
            </div>
            <div className="ant-table-cell ant-table-cell-fix-left" style={{ width: getColumnWidth('ngs') }}>
                <Form.Item name="ngs" style={{ margin: 0 }} initialValue={record.ngs}>
                    <Select style={{ width: '100%' }} ref={inputRefs.ngs} onKeyDown={onKeyDown}>
                        <Option value={false}>Нет</Option>
                        <Option value={true}>Да</Option>
                    </Select>
                </Form.Item>
            </div>
            <div className="ant-table-cell ant-table-cell-fix-left" style={{ width: getColumnWidth('substance_type_id') }}>
                <Form.Item name="substance_type_id" style={{ margin: 0 }} initialValue={record.substance_type_id}>
                    <Select style={{ width: '100%' }} ref={inputRefs.substance_type_id} onKeyDown={onKeyDown}>
                        <Option value={1}>Раствор</Option>
                        <Option value={2}>Лиофилизат</Option>
                    </Select>
                </Form.Item>
            </div>
            <div className="ant-table-cell ant-table-cell-fix-left" style={{ width: getColumnWidth('comment') }}>
                <Form.Item name="comment" style={{ margin: 0 }} initialValue={record.comment}>
                    <TextArea ref={inputRefs.comment} onKeyDown={onKeyDown} rows={3} style={{ width: '95%' }} />
                </Form.Item>
            </div>
            <div className="ant-table-cell ant-table-cell-fix-left" style={{ width: 85 }}>
                {!isValidation ? (
                    <>
                        <Button shape="circle" type="primary" icon={<CheckOutlined />} size="small" onClick={handleSave} style={{ marginRight: 8 }} />
                        <Button shape="circle" icon={<CloseOutlined />} size="small" onClick={onCancel} />
                    </>
                ) : (
                    <Spin />
                )}
            </div>
        </div>
    );
});

export default EditableRow;
