import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Input, Modal, Table} from "antd";
import PropTypes from "prop-types";

const extractText = (node) => {
    if (typeof node === 'string') {
        return node;
    }
    if (typeof node === 'object' && node !== null) {
        if (node.props && node.props.children) {
            return React.Children.toArray(node.props.children).map(extractText).join('');
        }
    }
    return '';
};

const saveColumnSettings = (stage, settings) => {
    localStorage.setItem(`${stage}_column_settings`, JSON.stringify(settings));
};

export const loadColumnSettings = (stage, defaults = {}) => {
    const settings = JSON.parse(localStorage.getItem(`${stage}_column_settings`) || '{}');
    return {
        hidden: settings?.hidden || defaults.hidden || [],
        widths: settings?.widths || defaults.widths || {},
        fixed: settings?.fixed || defaults.fixed || {},
        order: settings?.order || defaults.order || []
    };
};

const resetColumnSettings = (stage) => {
    localStorage.removeItem(`${stage}_column_settings`);
};

const getInitialSettings = (initialColumns) => {
    const widths = {};
    const fixed = {};
    const order = [];

    initialColumns.forEach(column => {
        if (column.width) {
            widths[column.dataIndex] = column.width;
        }
        if (column.fixed === 'left') {
            fixed[column.dataIndex] = 'left';
        }
        order.push(column.dataIndex);
    });

    return {
        hidden: [],
        widths,
        fixed,
        order
    };
};

const TableCustomizer = ({stage, initialColumns, onHide, open}) => {
    const [columnSettings, setColumnSettings] = useState(getInitialSettings(initialColumns));

    const data = initialColumns.map(column => ({
        id: column.dataIndex,
        title: extractText(column.title),
        dataIndex: column.dataIndex,
        defaultWidth: column.width
    }));

    const columns = [
        {
            title: 'Столбец',
            dataIndex: 'title',
            width: 200,
        },
        {
            title: 'Ширина',
            dataIndex: 'width',
            width: 120,
            render: (_, record) => (
                <Input
                    value={columnSettings.widths[record.dataIndex]}
                    onChange={(e) => {
                        const value = parseInt(e.target.value || 0);
                        handleWidthChange(record.dataIndex, value);
                    }}
                />
            )
        },
        {
            title: 'Закреплен слева',
            dataIndex: 'fixed',
            width: 120,
            render: (_, record) => (
                <Checkbox
                    checked={columnSettings.fixed[record.dataIndex] === 'left'}
                    onChange={(e) => handleFixedChange(record.dataIndex, e.target.checked)}
                />
            )
        },
        {
            title: 'Переместить',
            dataIndex: 'move',
            width: 120,
            render: (_, record) => (
                <>
                    <Button size={'small'}
                        onClick={() => moveColumnUp(record.dataIndex)}
                        disabled={columnSettings.order.indexOf(record.dataIndex) === 0}
                    >&#8593;</Button>
                    <Button size={'small'}
                        onClick={() => moveColumnDown(record.dataIndex)}
                        disabled={columnSettings.order.indexOf(record.dataIndex) === columnSettings.order.length - 1}
                    >
                        &#8595;
                    </Button>
                </>
            )
        }
    ];

    useEffect(() => {
        const savedSettings = loadColumnSettings(stage, getInitialSettings(initialColumns));
        setColumnSettings(savedSettings);
    }, [stage, initialColumns]);

    const handleWidthChange = (dataIndex, width) => {
        setColumnSettings(prev => ({
            ...prev,
            widths: {
                ...prev.widths,
                [dataIndex]: width
            }
        }));
    };

    const handleFixedChange = (dataIndex, isFixed) => {
        setColumnSettings(prev => {
            const newFixed = {...prev.fixed};
            if (isFixed) {
                newFixed[dataIndex] = 'left';
            } else {
                delete newFixed[dataIndex];
            }
            return {
                ...prev,
                fixed: newFixed
            };
        });
    };

    const handleSelectionChange = (selectedRowKeys) => {
        const hiddenColumns = initialColumns
            .map(column => column.dataIndex)
            .filter(dataIndex => !selectedRowKeys.includes(dataIndex));

        setColumnSettings(prev => ({
            ...prev,
            hidden: hiddenColumns
        }));
    };

    const getVisibleColumns = () => {
        return initialColumns
            .map(column => column.dataIndex)
            .filter(dataIndex => !columnSettings.hidden.includes(dataIndex));
    };

    const moveColumnUp = (dataIndex) => {
        const index = columnSettings.order.indexOf(dataIndex);
        if (index === 0) return;

        const newOrder = [...columnSettings.order];
        newOrder.splice(index - 1, 0, newOrder.splice(index, 1)[0]);
        setColumnSettings(prev => ({
            ...prev,
            order: newOrder
        }));
    };

    const moveColumnDown = (dataIndex) => {
        const index = columnSettings.order.indexOf(dataIndex);
        if (index === columnSettings.order.length - 1) return;

        const newOrder = [...columnSettings.order];
        newOrder.splice(index + 1, 0, newOrder.splice(index, 1)[0]);
        setColumnSettings(prev => ({
            ...prev,
            order: newOrder
        }));
    };

    const getOrderedData = () => {
        return columnSettings.order
            .map(dataIndex => data.find(item => item.dataIndex === dataIndex))
            .filter(Boolean);
    };

    const verifyData = () => {
        // как минимум 1 столбец должен быть видимым и быть закрепленным
        const hasVisibleFixedColumn = getVisibleColumns().some(dataIndex => columnSettings.fixed[dataIndex] === 'left');
        if (!hasVisibleFixedColumn) {
            // alert('Как минимум один столбец должен быть видимым и закрепленным слева');
            return false;
        }
        return true;
    }

    return (
        <Modal
            title="Настройка столбцов"
            open={open}
            destroyOnClose={true}
            onCancel={onHide}
            width={800}
            footer={[
                <Button key="reset" onClick={() => {
                    if (!window.confirm('Вы уверены, что хотите сбросить настройки?')) {
                        return;
                    }
                    resetColumnSettings(stage);
                    setTimeout(() => {
                        window.location.reload();
                    }, 100);
                }}>
                    Сбросить настройки
                </Button>,
                <Button key="save" type="primary" onClick={() => {
                    saveColumnSettings(stage, columnSettings);
                    onHide();
                }} disabled={!verifyData()}
                >Сохранить</Button>
            ]}
        >
            <Table
                rowKey="dataIndex"
                columns={columns}
                dataSource={getOrderedData()}
                scroll={{y: 400}}
                pagination={false}
                rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: getVisibleColumns(),
                    onChange: handleSelectionChange
                }}
            />
        </Modal>
    );
};

TableCustomizer.propTypes = {
    stage: PropTypes.string.isRequired,
    initialColumns: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired
};

export default TableCustomizer;
