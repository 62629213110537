import {Button, ConfigProvider, DatePicker, Input, Popover, Select, Tooltip} from "antd";
import {JoinColumnsManager} from "./Operations/JoinColumnsManager";
import {SplitPortions} from "./Operations/SplitPortions";
import {Defect} from "./Operations/Defect";
import {ServerOperations} from "./Operatoins";
import {Seating} from "./Operations/Seating";
import {Evaporation} from "./Operations/Evaporation";
import {NextStage} from "./Operations/NextStage";
import {OEMeasuring} from "./Operations/OEMeasuring";
import {RemoveFromCarrier} from "./Operations/RemoveFromCarrier";
import {Click} from "./Operations/Click";
import {Acylation} from "./Operations/Acylation";
import {Deblock} from "./Operations/Deblock";
import {OperationsTooltip} from "../../components/OperationsTooltip";
import {DissolutionFiltration} from "./Operations/DissolutionFiltration";
import {Desalination} from "./Operations/Desalination";
import ChromatographyVZH from "./Operations/CromeVzh";
import ChromatographyION from "./Operations/CromeIon";
import {Cartridge} from "./Operations/Cartridge";
import ToMass from "./Operations/ToMass";
import OpHistory from "../../components/OpHistory";
import {Elution} from "./Operations/Elution";
import {MKLMeasuring} from "./Operations/MKLMeasuring";
import {MassResults} from "./Operations/MassResults";
import {ToPackaging} from "./Operations/ToPackaging";
import {Mass} from "./Operations/Mass";
import {Dewaste} from "./Operations/Dewaste";
import {Detritylation} from "./Operations/Detritylation";
import {DescribePortion} from "./Operations/DescribePortion";
import * as PropTypes from "prop-types";
import {Places} from "../../lib/Places";
import {Phoresis} from "./Operations/Phoresis";
import {RemoveMalonate} from "./Operations/RemoveMalonate";
import {Resynth} from "./Operations/Resynth";
import MassSpectrTablet from "./Operations/Forms/MassSpectrTablet";
import {copyToClipboard, cut, format, makeFilelink} from "../../lib/util";
import locale from 'antd/lib/locale/ru_RU';
import {useEffect, useState} from "react";
import {CopyOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import OrderHistoryList from "../../components/OrderHistoryList";
import {YesBadge} from "../Packaging/PackagingEdit";
import {DeleteOperation} from "./Operations/DeleteOperation";

export const operations = {
    JOIN: 1,                // объединение пробирок
    SPLIT: 2,               // разъединение пробирок
    OE_MEASURING: 3,        // измерение ОЕ
    DEBLOCK: 4,             // деблокирование
    REMOVE_FROM_CARRIER: 5, // снять с носителя
    DEFECT: 6,              // указать бракованные
    NEXT_STAGE: 7,          // передать на следующий этап
    SEATING: 8,             // высаживание
    EVAPORATION: 9,         // упаривание
    CLICK: 10,              // клик
    ACYLATION: 11,          // ацилирование
    CHROMATOGRAPHY_VZH: 12, // хромотографирование
    CHROMATOGRAPHY_ION: 13, // хромотографирование
    DESALINATION: 14,       // обессоливание
    GLENPACK: 15,           // Glen pack
    DISSOLUTION_FILTRATION: 16, // Фильтрация
    TO_MASS_SPECTR: 17,        // Отправка на масс-спектрометрию
    MASS_SPECTR: 18,        // Масс-спектрометрия
    ELUTION: 19,            // Элюция
    PHORESIS: 20,           // Форез
    MKL_MEASURING: 21,      // Измерение МЛ
    MASS_RESULTS: 22,       // Масс-спектрометрия внесение результатов
    TO_PACKAGING: 23,       // На фасовку
    DEWASTE: 24,            // Отправить из брака на этап
    DETRITYLATION: 25,      // Детритилирование
    EVAPORATION_COMPLETE: 26, // Принудительное завершение упаривания
    ADD_PREFIX: 27,         // Добавить префикс
    REMOVE_MALONATE: 28,    // Снятие малоната
    EXTRASYNTHESIS: 1000,      // Ресинтез
    RESYNTHESIS: 1001,      // Пересинтез
    MASS_SPECTR_TABLET: 1002, // Хроматографирование (планшет)
    DELETE_OPERATION: 1003, // Удалить операцию
    CANCEL_ALIQUOT: 1004,   // Отменить аликвоты
}

export const operationMarkup = [];

operationMarkup[operations.JOIN] = {id: operations.JOIN, title: 'Объединить', disabled: true};
operationMarkup[operations.SPLIT] = {id: operations.SPLIT, title: 'Разъединить'};
operationMarkup[operations.OE_MEASURING] = {id: operations.OE_MEASURING, title: 'Измерение О.Е.'};
operationMarkup[operations.DEBLOCK] = {id: operations.DEBLOCK, title: 'Деблокировать'};
operationMarkup[operations.REMOVE_FROM_CARRIER] = {id: operations.REMOVE_FROM_CARRIER, title: 'Отделить от носителя'};
operationMarkup[operations.DEFECT] = {id: operations.DEFECT, title: 'Брак'};
operationMarkup[operations.CANCEL_ALIQUOT] = {id: operations.CANCEL_ALIQUOT, title: 'Отменить аликвоту'};
operationMarkup[operations.NEXT_STAGE] = {id: operations.NEXT_STAGE, title: 'Передать на следующий этап'};
operationMarkup[operations.EVAPORATION] = {id: operations.EVAPORATION, title: 'Упаривание'};
operationMarkup[operations.SEATING] = {id: operations.SEATING, title: 'Высаживание'};
operationMarkup[operations.CLICK] = {id: operations.CLICK, title: 'Клик'};
operationMarkup[operations.ACYLATION] = {id: operations.ACYLATION, title: 'Ацилирование'};
operationMarkup[operations.CHROMATOGRAPHY_VZH] = {id: operations.CHROMATOGRAPHY_VZH, title: 'Хроматографирование'};
operationMarkup[operations.CHROMATOGRAPHY_ION] = {id: operations.CHROMATOGRAPHY_ION, title: 'Хроматографирование'};
operationMarkup[operations.DESALINATION] = {id: operations.DESALINATION, title: 'Обессоливание'};
operationMarkup[operations.GLENPACK] = {id: operations.GLENPACK, title: 'Картриджная очистка'};
operationMarkup[operations.DISSOLUTION_FILTRATION] = {
    id: operations.DISSOLUTION_FILTRATION,
    title: 'Фильтрация'
};
operationMarkup[operations.TO_MASS_SPECTR] = {id: operations.TO_MASS_SPECTR, title: 'Масс-спектр'};
operationMarkup[operations.MASS_SPECTR] = {id: operations.MASS_SPECTR, title: 'Масс-спектр'};
operationMarkup[operations.ELUTION] = {id: operations.ELUTION, title: 'Элюция'};
operationMarkup[operations.PHORESIS] = {id: operations.PHORESIS, title: 'Форез'};
operationMarkup[operations.MKL_MEASURING] = {id: operations.MKL_MEASURING, title: 'Измерение МЛ'};
operationMarkup[operations.MASS_RESULTS] = {id: operations.MASS_RESULTS, title: 'Внести результаты'};
operationMarkup[operations.TO_PACKAGING] = {id: operations.TO_PACKAGING, title: 'На фасовку'};
operationMarkup[operations.DEWASTE] = {id: operations.DEWASTE, title: 'Восстановить пробирки'};
operationMarkup[operations.DETRITYLATION] = {id: operations.DETRITYLATION, title: 'Детритилирование'};
operationMarkup[operations.EVAPORATION_COMPLETE] = {id: operations.EVAPORATION_COMPLETE, title: 'Завершить упаривание'};
operationMarkup[operations.ADD_PREFIX] = {id: operations.ADD_PREFIX, title: 'Сведения о пробирке'};
operationMarkup[operations.REMOVE_MALONATE] = {id: operations.REMOVE_MALONATE, title: 'Снятие малоната'};
operationMarkup[operations.EXTRASYNTHESIS] = {id: operations.EXTRASYNTHESIS, title: 'Досинтез'};
operationMarkup[operations.RESYNTHESIS] = {id: operations.RESYNTHESIS, title: 'Пересинтез'};
operationMarkup[operations.MASS_SPECTR_TABLET] = {id: operations.MASS_SPECTR_TABLET, title: 'Масс-спектр (планшет)'};
operationMarkup[operations.DELETE_OPERATION] = {id: operations.DELETE_OPERATION, title: 'Удалить операцию'};

export const SynthStatuses = {
    NONE: 0,
    FILLING_STARTED: 1,
    SYNTH_STARTED: 2,
    SYNTH_DONE: 3
}

export const SynthStatusLabels = {}
SynthStatuses[SynthStatuses.NONE] = 'Без статуса';
SynthStatuses[SynthStatuses.FILLING_STARTED] = 'Начато заполнение';
SynthStatuses[SynthStatuses.SYNTH_STARTED] = 'Начат синтез';
SynthStatuses[SynthStatuses.SYNTH_DONE] = 'Синтез завершен';

Places.propTypes = {data: PropTypes.any};

export function useColumns(showFilters = true, displayMKLField = true, displayMKGField = true, extraColumns = [], excludeColumns = []) {

    const [tempFilters, setTempFilters] = useState({});
    const [filters, setFilters] = useState({});

    useEffect(() => {
        let handler = setTimeout(() => {
            setFilters({...tempFilters});
        }, 1000);
        return () => clearTimeout(handler);
    }, [tempFilters]);

    let columns = getDefaultColumns(tempFilters, setTempFilters, showFilters, displayMKLField,
        displayMKGField, extraColumns, excludeColumns);

    return {columns, filters, setFilters};
}

export const renderSequence = (value, record, index, defaultLength = 34) => {
    const workSequence = record.work_sequence || '-';
    const targetSequence = record.target_sequence || '-';
    const shortWorkSequence = workSequence.length > defaultLength ? '...' +
        workSequence.substring(workSequence.length - defaultLength, workSequence.length) : workSequence;

    const color = targetSequence === workSequence ? '#dedede' : 'rgba(2,130,75)';
    const content = <div>
        <div>Целевая: {targetSequence}</div>
        <div>Рабочая: {workSequence}</div>
    </div>;
    return <div>{shortWorkSequence}
        <Tooltip destroyTooltipOnHide={true}
                 placement={'left'}
                 color={'#1a8d90'}
                 autoAdjustOverflow={true}
                 arrow={{pointAtCenter: true}}
                 title={content}>
            <Button style={{float: "right", backgroundColor: color, borderColor: color, marginLeft: 10}}
                    type="primary" size={'small'}>i</Button>
        </Tooltip></div>;
}


/**
 * Рендерит текущий этап
 * @param value
 * @returns {*|JSX.Element}
 */
export function renderCurrentStage(value) {
    if (value?.data?.shelf) {
        return <>{value.name}<Tooltip
            title={<>
                <div>
                    Холодильник: {value.data.refrigerator}, Полка: {value.data.shelf}, Штатив:
                    {value.data.stand}, Позиция: {value.data.position}
                </div>
                <div>Комментарий: {value.data.comment}</div>
            </>}
            color={'#1a8d90'} arrow={{pointAtCenter: true}}
        >
            <Button type={'primary'} style={{marginLeft: 10}} size={'small'}>i</Button>
        </Tooltip></>;
    } else {
        return value.name;
    }
}

/**
 * Колонки по умолчанию
 *
 * @param filters
 * @param setFilters
 * @param showFilters
 * @param displayMKLField
 * @param displayMKGField
 * @param extraColumns - может содержать поля before и after с указанием до или после какой колонки вставить
 * @returns {[]}
 *
 * @returns {[{dataIndex: string, width: number, fixed: string, title: string, render: (function(*, *, *): *), key: string},{dataIndex: string, width: number, fixed: string, title: *, key: string},{dataIndex: string, width: number, fixed: string, title: *, render: (function(*, *, *): string), key: string},{dataIndex: string, width: number, fixed: string, title: *, render: (function(*, *, *): string), key: string},{dataIndex: string, width: number, fixed: string, title: *, render: (function(*, *, *): string|JSX.Element), key: string},null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]}
 */

let handler = null;

export function getDefaultColumns(filters, setFilters, showFilters, displayMKLField = true,
                                  displayMKGField = true, extraColumns = [], excludeColumns = []) {

    function getColumnTitle(title, fieldName = '', filtered = false, filterType = 'text', filterData = null) {

        function handleFilter(value) {
            let filter = filters;
            if (value) {
                filter[fieldName] = value;
            } else {
                delete filter[fieldName];
            }
            setFilters({...filter});
        }

        const renderFilterField = (type, data) => {
            let value = null;
            switch (type) {
                case 'select':
                    return <Select size={'small'} style={{width: 200}} onChange={handleFilter}>
                        {data.map((item, idx) => <Select.Option key={idx}
                                                                value={item.value}>{item.label}</Select.Option>)}
                    </Select>;
                case 'date':
                    value = filters[fieldName] ? dayjs(filters[fieldName], 'DD.MM.YYYY') : null;
                    return <ConfigProvider locale={locale}>
                        <DatePicker size={'small'} value={value} onChange={handleFilter} format={'DD.MM.YYYY'}
                                    placeholder={'ДД.ММ.ГГГГ'}/>
                    </ConfigProvider>;
                default:
                    value = filters[fieldName] || '';
                    return <Input size={'small'} value={value} onChange={v => handleFilter(v.target.value)}/>;
            }
        }

        return showFilters && filterType ? <div>
                <div className="title-label">{title}</div>
                <div>{filtered && renderFilterField(filterType, filterData)}</div>
            </div> :
            <div className="title-label">{title}</div>;
    }

    function renderOpHistory(value, record, index) {
        return <OpHistory history={record.operations} record={record}/>;
    }

    let result = [
        // {title: 'ID', width: 100, fieldName: 'id', dataIndex: 'id', fixed: 'left',},
        {
            title: '№', dataIndex: 'index', key: 'index', width: 50, fixed: 'left',
            render: (val, row, idx) => {
                return <Popover content={row.id} trigger="hover">{idx + 1}</Popover>;
            }
        },
        {
            title: getColumnTitle('Дата передачи', 'current_stage_date', true, 'date'),
            fieldName: 'current_stage_date',
            dataIndex: 'current_stage_date',
            key: 'current_stage_date',
            fixed: 'left',
            width: 150,
            render: (val, row, idx) => {
                return dayjs(val).format('LL');
            }
        },
        {
            title: getColumnTitle('Дата синтеза', 'date', true, 'date'),
            dataIndex: 'date', key: 'date', width: 150, fixed: 'left', render(val, row, idx) {
                const date = dayjs(val).format('LL');
                return date === 'Invalid Date' ? '-' : date;
            }
        },
        {
            title: getColumnTitle('Оборудование/Запуск', 'device_name', true),
            dataIndex: 'device_name', key: 'device_name', width: 180, fixed: 'left'
        },
        {
            title: getColumnTitle('Позиция', 'place_name', true),
            dataIndex: 'place_name', key: 'place_name', width: 150, fixed: 'left'
        },
        {
            title: getColumnTitle('Масштаб', 'scale_name', true),
            dataIndex: 'scale_name',
            key: 'scale_name',
            width: 90, fixed: 'left',
            render: (val, row, idx) => {
                let result = null;
                if (row.places && row.places.length > 1) {
                    result = <div style={{display: 'flex', justifyContent: 'space-around'}}>
                        <span>{row.scale_name}</span>&nbsp;
                        <Tooltip destroyTooltipOnHide={true}
                                 placement={'left'}
                                 color={'#1a8d90'}
                                 autoAdjustOverflow={true}
                                 arrow={{pointAtCenter: true}}
                                 title={<Places
                                     data={row.places}/>}><Button type={'primary'} size={'small'}>i</Button></Tooltip>
                    </div>;
                } else {
                    result = cut(`${row.scale_name}`, 10);
                }
                return result;
            }
        },
        {
            title: getColumnTitle('Название', 'olig_name', true),
            dataIndex: 'olig_name',
            key: 'olig_name',
            width: 100, fixed: 'left',
            render: v => cut(v, 10)
        },
        {
            title: getColumnTitle('Префикс', 'prefix', true),
            dataIndex: 'prefix',
            key: 'prefix',
            width: 100, fixed: 'left',
            render: v => cut(v, 10)
        },
        {
            title: getColumnTitle('Последовательность', 'olig_sequence', true),
            dataIndex: 'olig_sequence',
            key: 'olig_sequence',
            width: 320, fixed: 'left', align: 'right',
            render: renderSequence,
        },
        {
            title: "Заказ", dataIndex: 'orders', width: 220,
            render: (v) => {
                return <OrderHistoryList orders={v || []}/>;
            }
        },
        {
            title: getColumnTitle('NGS', 'ngs', true), dataIndex: 'ngs', key: 'ngs', width: 70,
            render: (value) => {
                return <YesBadge status={value === 'Да'}/>
            }
        },
        {title: getColumnTitle('Длина', 'length', true), dataIndex: 'length', key: 'length', width: 80},
        {
            title: getColumnTitle('5\'', 'modification_5', true),
            dataIndex: 'modification_5',
            key: 'modification_5',
            width: 60, render: v => cut(v, 10)
        },
        {
            title: getColumnTitle('3\'', 'modification_3', true),
            dataIndex: 'modification_3',
            key: 'modification_3',
            width: 60, render: v => cut(v, 10)
        },
        {
            title: getColumnTitle('Вн. метка', 'inner', true),
            dataIndex: 'inner',
            key: 'inner',
            width: 100
        },
        {
            title: getColumnTitle('OE'),
            fieldName: 'quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 50,
            align: 'right',
            render: value => format(value)
        },
        {
            title: getColumnTitle('МЛ'),
            fieldName: 'mkl',
            dataIndex: 'mkl',
            key: 'mkl',
            width: 60,
            align: 'right',
            render: value => format(value)
        },
        {
            title: '260/280', key: '260_280', dataIndex: '260_280', width: 80,
            align: 'right',
            render: value => format(value)
        },
        {
            title: '260/230', key: '260_230', dataIndex: '260_230', width: 80,
            align: 'right',
            render: value => format(value)
        },
        {
            title: getColumnTitle('НМОЛЬ'),
            fieldName: 'nanomole',
            dataIndex: 'nanomole',
            key: 'nanomole',
            width: 80,
            align: 'right',
            render: value => format(value)
        },
        {
            title: getColumnTitle('E260', 'e260', true),
            dataIndex: 'e260',
            key: 'e260',
            width: 80,
            align: 'right',
            render: value => format(value)
        },

        {
            title: getColumnTitle('MW', 'mw', true),
            dataIndex: 'mw',
            key: 'mw',
            width: 80,
            align: 'right',
            render: value => format(value)
        },
        {
            title: getColumnTitle('МГ'),
            fieldName: 'mkg',
            dataIndex: 'mkg',
            key: 'mkg',
            width: 80,
            align: 'right',
            render: value => format(value)
        },
        {
            title: getColumnTitle('Пред. этап'), fieldName: 'previous_stage', dataIndex: 'previous_stage',
            key: 'previous_stage', width: 200,
            render: value => {
                return value?.name || '-';
            }
        },
        {
            title: getColumnTitle('Основание'), fieldName: 'reason', dataIndex: 'reason', key: 'reason', width: 200,
        },
        {
            title: getColumnTitle('История операций'), fieldName: 'operations', dataIndex: 'operations',
            key: 'operations', width: 220,
            render: renderOpHistory
        },
        {
            title: getColumnTitle('Комментарий'), fieldName: 'comment', dataIndex: 'comment',
            key: 'comment', width: 200,
            render: v => cut(v, 33),
        },
        {
            title: getColumnTitle('Комментарий на этап'), fieldName: 'stage_comment', dataIndex: 'stage_comment',
            key: 'stage_comment', width: 200,
            render: v => cut(v, 33)
        },
    ];

    if (!displayMKLField) {
        result = result.filter(item => item.fieldName !== 'mkl');
    }

    if (!displayMKGField) {
        result = result.filter(item => item.fieldName !== 'mkg');
    }

    if (extraColumns) {
        extraColumns.forEach((item) => {
            if (item.after) {
                const idx = result.findIndex((i) => i.dataIndex === item.after);
                if (idx > -1) {
                    result.splice(idx + 1, 0, item);
                } else {
                    result.push(item);
                }
            } else {
                result.push(item);
            }
            if (item.before) {
                const idx = result.findIndex((i) => i.dataIndex === item.before);
                if (idx > -1) {
                    result.splice(idx, 0, item);
                } else {
                    result.push(item);
                }
            }
        });
    }

    if (excludeColumns) {
        result = result.filter(item => {
            return excludeColumns.filter(column => column === item.fieldName).length === 0;
        });
    }

    const uniq = {};
    result = result.filter(obj => !uniq[obj.dataIndex] && (uniq[obj.dataIndex] = true));

    return result;
}

export function getDeblockColumns(infoField = true) {
    let result = {
        title: 'Деблокирование',
        dataIndex: 'deblock',
        children: [
            {
                title: 'Начало', width: 120, fieldName: 'deblock', dataIndex: 'deblock',
                render: (v, rec) => {
                    const val = renderJsonFieldWithPivot('started_at', v);
                    return val ? dayjs(val || '-').format('DD.MM.YYYY HH:mm') : '-';
                }
            },
            {
                title: 'Реактив', width: 100, fieldName: 'deblock', dataIndex: 'deblock',
                render: (v) => renderJsonFieldWithPivot('reactive', v)
            },
            {
                title: 't', width: 100, fieldName: 'deblock', dataIndex: 'deblock',
                render: (v) => renderJsonFieldWithPivot('temperature', v),
            },
            {
                title: 'Время',
                width: 100,
                fieldName: 'deblock',
                dataIndex: 'deblock',
                render: (v) => renderJsonFieldWithPivot('time', v)
            },
            {
                title: 'Фаза',
                width: 100,
                fieldName: 'deblock',
                dataIndex: 'deblock',
                render: (v) => renderJsonFieldWithPivot('phase', v)
            },
            {
                title: 'Завершение',
                width: 120,
                fieldName: 'deblock',
                dataIndex: 'deblock',
                render: (v) => {
                    const val = renderJsonFieldWithPivot('finished_at', v);
                    return val ? dayjs(val || '-').format('DD.MM.YYYY HH:mm') : '-';
                }

            }
        ]
    }
    if (infoField) {
        result.children.push(
            OperationsTooltip('deblock', [getDeblockColumns(false)])
        );
    }
    return result;
}

export function getRemoveFromCarrierColumns(infoField = true) {
    let result = {
        title: 'Снятие',
        dataIndex: 'remove_from_carrier',
        children: [
            {
                title: 'Реактив',
                width: 100,
                fieldName: 'remove_from_carrier',
                dataIndex: 'remove_from_carrier',
                render: (v) => renderJsonFieldWithPivot('reactive', v),
            },
            {
                title: 'Объём, мл',
                width: 100,
                fieldName: 'remove_from_carrier',
                dataIndex: 'remove_from_carrier',
                render: (v) => renderJsonFieldWithPivot('volume', v),
            },
            {
                title: 'Время',
                width: 100,
                fieldName: 'remove_from_carrier',
                dataIndex: 'remove_from_carrier',
                render: (v) => renderJsonFieldWithPivot('time', v),
            },
            {
                title: 'Повторы, шт.',
                width: 100,
                fieldName: 'remove_from_carrier',
                dataIndex: 'remove_from_carrier',
                render: (v) => renderJsonFieldWithPivot('repeating', v),
            },
        ]
    };
    if (infoField) {
        result.children.push(
            OperationsTooltip('remove_from_carrier', [getRemoveFromCarrierColumns(false)])
        );
    }
    return result;

}

export function getDesalinationColumns(infoField = true) {
    let result = {
        title: 'Обессоливание',
        dataIndex: 'desalination',
        children: [
            {
                title: 'Реактив',
                width: 100,
                fieldName: 'desalination',
                dataIndex: 'desalination',
                render: (v) => renderJsonFieldWithPivot('reactive', v),
            },
        ]
    };
    if (infoField) {
        result.children.push(
            OperationsTooltip('desalination', [getDesalinationColumns(false)])
        );
    }
    return result;

}


export function getEvaporationColumns(infoField = true) {
    let result = {
        title: 'Упаривание',
        dataIndex: 'evaporation',
        children: [
            {
                title: 'Начало',
                width: 120,
                fieldName: 'evaporation',
                dataIndex: 'evaporation',
                render: (v) => {
                    const val = renderJsonFieldWithPivot('started_at', v);
                    return val ? dayjs(val || '-').format('DD.MM.YYYY HH:mm') : '-';
                }
            },
            {
                title: 'Длительность',
                width: 110,
                fieldName: 'evaporation',
                dataIndex: 'evaporation',
                render: (v) => duration(renderJsonFieldWithPivot('duration', v)),
            },
            {
                title: 'Температура, °С',
                width: 120,
                fieldName: 'evaporation',
                dataIndex: 'evaporation',
                render: (v) => renderJsonFieldWithPivot('temperature', v)
            },
            {
                title: '№ концентратора',
                width: 140,
                fieldName: 'evaporation',
                dataIndex: 'evaporation',
                render: (v) => renderJsonFieldWithPivot('concentratorNum', v)
            },
            {
                title: 'Завершение',
                width: 120,
                fieldName: 'evaporation',
                dataIndex: 'evaporation',
                render: (v) => {
                    const val = renderJsonFieldWithPivot('finished_at', v);
                    return val ? dayjs(val || '-').format('DD.MM.YYYY HH:mm') : '-';
                }
            },
        ]
    };
    if (infoField) {
        result.children.push(
            OperationsTooltip('evaporation', [getEvaporationColumns(false)])
        );
    }
    return result;
}

export function getSeatingColumns(infoField = true) {

    let result = {
        title: 'Высаживание',
        dataIndex: 'seating',
        children: [
            {
                title: 'Метод',
                width: 150,
                fieldName: 'seating',
                dataIndex: 'seating',
                render: (v) => renderJsonFieldWithPivot('method', v)
            },
        ]
    };
    if (infoField) {
        result.children.push(
            OperationsTooltip('seating', [getSeatingColumns(false)])
        );
    }
    return result;

}


export function getClickColumns(infoField = true) {
    let result = {
        title: 'Клик',
        dataIndex: 'click',
        children: [
            {
                title: 'Начало',
                width: 100,
                fieldName: 'click',
                dataIndex: 'click',
                render: (v) => {
                    const val = renderJsonFieldWithPivot('started_at', v);
                    return val ? dayjs(val || '-').format('DD.MM.YYYY HH:mm') : '-';
                }
            },
            {
                title: 'Краска',
                width: 100,
                fieldName: 'click',
                dataIndex: 'click',
                render: (v) => renderJsonFieldWithPivot('paint_name', v)
            },
            {
                title: 'Завершение',
                width: 100,
                fieldName: 'click',
                dataIndex: 'click',
                render: (v) => {
                    const val = renderJsonFieldWithPivot('finished_at', v);
                    return val ? dayjs(val || '-').format('DD.MM.YYYY HH:mm') : '-';
                }
            },
        ]
    };
    if (infoField) {
        result.children.push(
            OperationsTooltip('click', [getClickColumns(false)])
        );
    }
    return result;

}


export function getChromeColumns(infoField = true) {
    let result = {
        title: 'Хроматографирование',
        dataIndex: 'chromatography',
        children: [
            {
                title: 'Основание',
                width: 100,
                fieldName: 'chromatography',
                dataIndex: 'chromatography',
                render: (v) => renderJsonFieldWithPivot('basis', v)
            },
            {
                title: 'Хроматограф',
                width: 100,
                fieldName: 'chromatography',
                dataIndex: 'chromatography',
                render: (v) => renderJsonFieldWithPivot('chromatograph', v)
            },
            {
                title: 'Колонка',
                width: 100,
                fieldName: 'chromatography',
                dataIndex: 'chromatography',
                render: (v) => renderJsonFieldWithPivot('column', v)
            },
            {
                title: 'Метод',
                width: 100,
                fieldName: 'chromatography',
                dataIndex: 'chromatography',
                render: (v) => renderJsonFieldWithPivot('method', v)
            },
        ]
    };
    if (infoField) {
        result.children.push(
            OperationsTooltip('chromatography', [getChromeColumns(false)])
        );
    }
    return result;

}

export function getMassSpectrColumns() {

    return [
        {
            title: 'Этап производства',
            width: 110,
            fieldName: 'current_stage_name',
            dataIndex: 'current_stage_name',
        },
        {
            title: 'Группа',
            width: 100,
            fieldName: 'tablet_from',
            dataIndex: 'tablet_from',
        },
        {
            title: 'Дата передачи',
            width: 150,
            fieldName: 'tablet_when',
            dataIndex: 'tablet_when',
            render: (v) => {
                return v ? dayjs(v || '-').format('LLL') : '-';
            }
        },
        {
            title: 'Номер планшета',
            width: 150,
            fieldName: 'tablet_number',
            dataIndex: 'tablet_number',
        },
        {
            title: 'Позиция на планшете',
            width: 100,
            fieldName: 'tablet_position',
            dataIndex: 'tablet_position',
        },
    ];

}


export function getAcylationColumns(infoField = true) {
    let result = {
        title: 'Ацилирование',
        dataIndex: 'acylation',
        children: [
            {
                title: 'Начало',
                width: 200,
                fieldName: 'acylation',
                dataIndex: 'acylation',
                render: (v) => {
                    const val = renderJsonFieldWithPivot('started_at', v);
                    return val ? dayjs(val || '-').format('DD.MM.YYYY HH:mm') : '-';
                }
            },
            {
                title: 'Краска',
                width: 100,
                fieldName: 'acylation',
                dataIndex: 'acylation',
                render: (v) => {
                    return renderJsonFieldWithPivot('paint_name', v);
                }
            },
            {
                title: 'Завершение',
                width: 200,
                fieldName: 'acylation',
                dataIndex: 'acylation',
                render: (v) => {
                    const val = renderJsonFieldWithPivot('finished_at', v);
                    return val ? dayjs(val || '-').format('DD.MM.YYYY HH:mm') : '-';
                }
            },
        ]
    };
    if (infoField) {
        result.children.push(
            OperationsTooltip('acylation', [getAcylationColumns(false)])
        );
    }
    return result;

}

export function getDissolationColumns(infoField = true) {
    let result = {
        title: 'Фильтрация',
        dataIndex: 'dissolation',
        children: [
            {
                title: 'Реактив',
                width: 100,
                fieldName: 'dissolation',
                dataIndex: 'dissolation',
                render: (v) => renderJsonFieldWithPivot('reactive', v)
            },
        ]
    };
    if (infoField) {
        result.children.push(
            OperationsTooltip('dissolation', [getDissolationColumns(false)])
        );
    }
    return result;
}

export function getDitritylationColumns(infoField = true) {
    let result = {
        title: 'Детритилирование',
        dataIndex: 'detritylation',
        children: [
            {
                title: 'Примечание',
                width: 100,
                fieldName: 'detritylation',
                dataIndex: 'detritylation',
                render: (v) => renderJsonFieldWithPivot('notes', v)
            }
        ]
    };
    if (infoField) {
        result.children.push(
            OperationsTooltip('detritylation', [getDitritylationColumns(false)])
        );
    }
    return result;
}

export function getMalonateColumns(infoField = true) {
    let result = {
        title: 'Снятие малоната',
        dataIndex: 'malonate',
        children: [
            {
                title: 'Примечание',
                width: 100,
                fieldName: 'malonate',
                dataIndex: 'malonate',
                render: (v) => renderJsonFieldWithPivot('notes', v)
            }
        ]
    };
    if (infoField) {
        result.children.push(
            OperationsTooltip('malonate', [getDitritylationColumns(false)])
        );
    }
    return result;
}


export function getGlenpackColumns(infoField = true) {
    let result = {
        title: 'Картриджная очистка',
        dataIndex: 'glenpack',
        children: [
            {
                title: 'Дата начала',
                width: 100,
                fieldName: 'glenpack',
                dataIndex: 'glenpack',
                render: (v) => {
                    const val = renderJsonFieldWithPivot('started_at', v);
                    return val ? dayjs(val || '-').format('DD.MM.YYYY') : '-';
                }
            },
            {
                title: 'Метод',
                width: 100,
                fieldName: 'glenpack',
                dataIndex: 'glenpack',
                render: (v) => renderJsonFieldWithPivot('method', v)
            },
            {
                title: 'Позиция',
                width: 100,
                fieldName: 'glenpack',
                dataIndex: 'glenpack',
                render: (v) => renderJsonFieldWithPivot('position', v)
            },
            {
                title: '№ Планшета',
                width: 100,
                fieldName: 'glenpack',
                dataIndex: 'glenpack',
                render: (v) => renderJsonFieldWithPivot('tablet_number', v)
            },
            {
                title: 'Дата завершения',
                width: 100,
                fieldName: 'glenpack',
                dataIndex: 'glenpack',
                render: (v) => {
                    const val = renderJsonFieldWithPivot('finished_at', v);
                    return val ? dayjs(val || '-').format('DD.MM.YYYY') : '-';
                }
            },
            {
                title: 'Комментарий',
                width: 100,
                fieldName: 'glenpack',
                dataIndex: 'glenpack',
                render: (v) => renderJsonFieldWithPivot('comment', v)
            },
        ]
    };
    if (infoField) {
        result.children.push(
            OperationsTooltip('glenpack', [getGlenpackColumns(false)])
        );
    }
    return result;
}

export function getPhoresisColumns(infoField = true) {
    let result = {
        title: 'Форез',
        dataIndex: 'phoresis',
        children: [
            {
                title: 'Начало',
                width: 100,
                fieldName: 'phoresis',
                dataIndex: 'phoresis',
                render: (v) => {
                    const val = renderJsonFieldWithPivot('started_at', v);
                    return val ? dayjs(val || '-').format('DD.MM.YYYY HH:mm') : '-';
                }
            },
            {
                title: 'Завершение',
                width: 100,
                fieldName: 'phoresis',
                dataIndex: 'phoresis',
                render: (v) => {
                    const val = renderJsonFieldWithPivot('finished_at', v);
                    return val ? dayjs(val || '-').format('DD.MM.YYYY HH:mm') : '-';
                }
            },
            {
                title: 'Концентрация', width: 100, fieldName: 'phoresis', dataIndex: 'phoresis',
                render: (v) => renderJsonFieldWithPivot('concentration', v)
            },
            {
                title: 'Кол-во ячеек', width: 100, fieldName: 'phoresis', dataIndex: 'phoresis',
                render: (v) => renderJsonFieldWithPivot('cell_count', v)
            },
        ]
    };
    if (infoField) {
        result.children.push(
            OperationsTooltip('phoresis', [getPhoresisColumns(false)])
        );
    }
    return result;

}


export function getElutionColumns(infoField = true) {
    let result = {
        title: 'Элюция',
        dataIndex: 'elution',
        children: [
            {
                title: 'Начало',
                width: 100,
                fieldName: 'elution',
                dataIndex: 'elution',
                render: (v) => {
                    const val = renderJsonFieldWithPivot('started_at', v);
                    return val ? dayjs(val || '-').format('DD.MM.YYYY HH:mm') : '-';
                }
            },
            {
                title: 'Завершение',
                width: 100,
                fieldName: 'elution',
                dataIndex: 'elution',
                render: (v) => {
                    const val = renderJsonFieldWithPivot('finished_at', v);
                    return val ? dayjs(val || '-').format('DD.MM.YYYY HH:mm') : '-';
                }
            },
        ]
    };
    if (infoField) {
        result.children.push(
            OperationsTooltip('elution', [getElutionColumns(false)])
        );
    }
    return result;

}

export function getMassResultsColumns(infoField = true) {
    let result = {
        title: 'Результаты',
        dataIndex: 'mass_results',
        children: [
            {
                title: 'Дата анализа',
                width: 100,
                fieldName: 'measurements',
                dataIndex: 'measurements',
                render: (v) => {
                    if (v?.started_at) {
                        return dayjs(v.started_at).format('DD.MM.YYYY');
                    }
                }
            },
            {
                title: 'Чистота, %',
                width: 100,
                fieldName: 'measurements',
                dataIndex: 'measurements',
                render: (v) => renderJsonField('purity', v?.params)
            },
            {
                title: 'N-1, %',
                width: 100,
                fieldName: 'measurements',
                dataIndex: 'measurements',
                render: (v) => renderJsonField('nminus1', v?.params)
            },
            {
                title: 'N+1, %',
                width: 100,
                fieldName: 'measurements',
                dataIndex: 'measurements',
                render: (v) => renderJsonField('nplus1', v?.params)
            },
            {
                title: 'Дополнительно',
                width: 150,
                fieldName: 'measurements',
                dataIndex: 'measurements',
                render: (v) => renderJsonField('additional', v?.params)
            },
            {
                title: 'Ссылка на результат',
                width: 200,
                fieldName: 'measurements',
                dataIndex: 'measurements',
                render: (v) => {
                    if (v?.params?.link) {
                        const link = makeFilelink(v.params?.link);
                        return <><a href={link} rel={'noreferrer'} target="_blank">Открыть</a>
                            <Button size={'small'} icon={<CopyOutlined/>}
                                    style={{border: 0}}
                                    onClick={() => copyToClipboard(
                                        link, "Ссылка скопирована в буфер обмена")}
                            />
                        </>
                    }
                    return '-';
                }
            },
            {
                title: 'Заключение',
                width: 100,
                fieldName: 'measurements',
                dataIndex: 'measurements',
                render: (v) => renderJsonField('conclusion', v?.params)
            },
        ]
    };

    if (infoField) {
        result.children.push(
            OperationsTooltip('measurements', [getMassResultsColumns(false)])
        );
    }

    return result;
}

export function getStockColumns(infoField = true) {
    return {
        title: 'База остатков',
        dataIndex: 'stock',
        children: [
            {
                title: 'Холодильник',
                width: 100,
                fieldName: 'stock',
                dataIndex: 'stock',
                render: (v) => renderJsonField('refrigerator', v)
            },
            {
                title: 'Полка',
                width: 100,
                fieldName: 'stock',
                dataIndex: 'stock',
                render: (v) => renderJsonField('shelf', v)
            },
            {
                title: 'Штатив',
                width: 100,
                fieldName: 'stock',
                dataIndex: 'stock',
                render: (v) => renderJsonField('stand', v)
            },
            {
                title: 'Позиция',
                width: 100,
                fieldName: 'stock',
                dataIndex: 'stock',
                render: (v) => renderJsonField('position', v)
            },
            {
                title: 'Комментарий',
                width: 100,
                fieldName: 'stock',
                dataIndex: 'stock',
                render: (v) => renderJsonField('comment', v)
            }
        ]
    };
}


export function getOperations(selectedRows, activeOperation, onHide, onChange, currentStage, options) {

    switch (activeOperation) {
        case operations.JOIN:
            return <JoinColumnsManager data={selectedRows} onHide={onHide} options={options}/>;
        case operations.SPLIT:
            return <SplitPortions data={selectedRows} onHide={onHide} options={options}/>;
        case operations.DEBLOCK:
            return <Deblock data={selectedRows} onHide={onHide} options={options}/>;
        case operations.EVAPORATION:
            return <Evaporation data={selectedRows} onHide={onHide} options={options}/>;
        case operations.REMOVE_FROM_CARRIER:
            return <RemoveFromCarrier data={selectedRows} onHide={onHide} options={options}/>;
        case operations.OE_MEASURING:
            return <OEMeasuring data={selectedRows} onHide={onHide} options={options}/>;
        case operations.DEFECT:
            return <Defect data={selectedRows} onHide={onHide} options={options}/>;
        case operations.CANCEL_ALIQUOT:
            return <Defect data={selectedRows} onHide={onHide} options={options} title={"Отменить аликвоту"}/>;
        case operations.NEXT_STAGE:
            return <NextStage data={selectedRows} onHide={onHide} excludeStageId={currentStage} options={options}/>;
        case operations.SEATING:
            return <Seating data={selectedRows} onHide={onHide} options={options}/>;
        case operations.CLICK:
            return <Click data={selectedRows} onHide={onHide} options={options}/>;
        case operations.ACYLATION:
            return <Acylation data={selectedRows} onHide={onHide} options={options}/>;
        case operations.CHROMATOGRAPHY_VZH:
            return <ChromatographyVZH data={selectedRows} onHide={onHide} options={options}/>;
        case operations.CHROMATOGRAPHY_ION:
            return <ChromatographyION data={selectedRows} onHide={onHide} options={options}/>;
        case operations.DISSOLUTION_FILTRATION:
            return <DissolutionFiltration data={selectedRows} onHide={onHide} options={options}/>;
        case operations.DESALINATION:
            return <Desalination data={selectedRows} onHide={onHide} options={options}/>;
        case operations.GLENPACK:
            return <Cartridge data={selectedRows} onHide={onHide} options={options}/>;
        case operations.TO_MASS_SPECTR:
            return <ToMass data={selectedRows} onHide={onHide} options={options} StageId={currentStage}/>;
        case operations.MASS_SPECTR:
            return <Mass data={selectedRows} onHide={onHide} options={options}/>;
        case operations.ELUTION:
            return <Elution data={selectedRows} onHide={onHide} options={options}/>;
        case operations.PHORESIS:
            return <Phoresis data={selectedRows} onHide={onHide} options={options}/>;
        case operations.MKL_MEASURING:
            return <MKLMeasuring data={selectedRows} onHide={onHide} options={options}/>;
        case operations.MASS_RESULTS:
            return <MassResults data={selectedRows} onHide={onHide} onChange={onChange} options={options}/>;
        case operations.TO_PACKAGING:
            return <ToPackaging data={selectedRows} onHide={onHide} options={options}/>;
        case operations.DEWASTE:
            return <Dewaste data={selectedRows} onHide={onHide} options={options}/>;
        case operations.DETRITYLATION:
            return <Detritylation data={selectedRows} onHide={onHide} options={options}/>;
        case operations.EVAPORATION_COMPLETE:
            return <Evaporation data={selectedRows} onHide={onHide} options={options} complete={true}/>;
        case operations.ADD_PREFIX:
            return <DescribePortion data={selectedRows} onHide={onHide} options={options}/>;
        case operations.REMOVE_MALONATE:
            return <RemoveMalonate data={selectedRows} onHide={onHide} options={options}/>;
        case operations.EXTRASYNTHESIS:
            return <Resynth data={selectedRows} onHide={onHide} type={2} options={options}/>;
        case operations.RESYNTHESIS:
            return <Resynth data={selectedRows} onHide={onHide} type={3} options={options}/>;
        case operations.MASS_SPECTR_TABLET:
            return <MassSpectrTablet data={selectedRows} onHide={onHide} options={options}/>;
        case operations.DELETE_OPERATION:
            return <DeleteOperation data={selectedRows} onHide={onHide} options={options}/>;
        default:
            return null;
    }

}

export function getDefaultOperationColumns(showId = false) {
    let result = [
        {
            title: 'Дата синтеза', dataIndex: 'date', key: 'date', width: 110,
            render(value, row) {
                const date = dayjs(value).format('LL');
                return date === 'Invalid Date' ? '-' : date;
            }
        },
        {
            title: 'Оборудование/Период',
            dataIndex: 'device_period',
            key: 'device_period',
            width: 50,
            render(value, row) {
                return `${row.device_short_name} / ${row.device_cycle_name}`;
            }
        },
        {
            title: 'Позиция пробирки',
            dataIndex: 'device_place_name',
            key: 'device_place_name',
            width: 100,
            render(value, row) {
                return `${row.device_place_name} / ${row.device_place_position}`;
            }
        },
        {title: 'Масштаб', dataIndex: 'scale_name', key: 'scale_name', width: 50},
        {title: 'Название', dataIndex: 'olig_name', key: 'olig_name', width: 50},
        {
            title: 'Последовательность', dataIndex: 'olig_sequence', key: 'field6', width: 230,
            render: renderSequence
        },
        {
            title: '5\'',
            dataIndex: 'modification_5',
            key: 'modification_5',
            width: 60, render: v => cut(v, 10)
        },
        {
            title: '3\'',
            dataIndex: 'modification_3',
            key: 'modification_3',
            width: 60, render: v => cut(v, 10)
        },
        {title: 'Тип', dataIndex: 'types', key: 'types', width: 50},
        {
            title: 'OE', dataIndex: 'quantity', key: 'quantity', width: 50,
            render(value, record) {
                return format(value);
            }
        },
        {
            title: 'НМОЛЬ', dataIndex: 'nanomole', key: 'nanomole', width: 50,
            render: (value) => {
                return format(value);
            }
        }
    ];
    if (showId) {
        result.unshift({title: 'ID', dataIndex: 'id', key: 'id', width: 50});
    }
    return result;
}

export function renderJsonFieldWithPivot(name, val) {

    if (val && val.pivot && val.pivot.params[name]) {
        return val.pivot.params[name]
    } else {
        return null;
    }
}

export function renderJsonField(name, json) {
    if (json && json[name]) {
        return json[name]
    } else {
        return null;
    }
}

export function duration(mins) {
    let result = '-';
    if (mins) {
        result = `${Math.round(mins / 60)} ч.`;
        if (mins % 60) {
            result += ` ${mins % 60} мин.`;
        }
    }
    return result;
}

function extractOperation(data, operation_id) {
    let result = null;
    if (data.operations) {
        result = data.operations.filter(rec => {
            return rec.pivot && rec.pivot.operation_id === operation_id
        });
        if (result.length) {
            result = result.map(item => {
                if (item.pivot && item.pivot.params) {
                    item.pivot.params = JSON.parse(item.pivot.params);
                }
                return item;
            });
            result = {...result[0], _all: result};
        }
    }
    return result;
}

function extractFromStages(data, stage_id, extractLastRecord = false) {
    let result = null;
    if (data.stages) {
        result = data.stages.filter(rec => {
            return rec.data && rec.id === stage_id
        });
        if (result.length) {
            if (extractLastRecord) {
                result = {...result[result.length - 1].data};
            } else {
                result = {...result[0].data};
            }
        }
    }
    return result;
}

export function mapDataFunction(data) {

    const mappedData = data.map(item => {
        const types = item.oligonucleotid?.types?.map(type => type.name).join(',') || '';

        const roundValue = 1000;
        const mkl = item.mkl ? Math.round(item.mkl * roundValue) / roundValue : '-';
        const mkg = item.mkg ? Math.round(item.mkg * roundValue) / roundValue : '-';
        const nanomole = item.nanomole ? Math.round(item.nanomole * roundValue) / roundValue : '-';
        const e260 = item.current_oligonucleotide?.e260 ? (Math.round(item.current_oligonucleotide.e260 * roundValue) / roundValue) : (item.oligonucleotid?.e260 ? Math.round(item.oligonucleotid?.e260 * roundValue) / roundValue : '-');
        const mw = item.current_oligonucleotide?.mw ? Math.round(item.current_oligonucleotide.mw * roundValue) / roundValue : (item.oligonucleotid?.mw ? Math.round(item.oligonucleotid?.mw * roundValue) / roundValue : '-');
        const date = item.places?.[0]?.calendar?.date || null;
        const device_short_name = item.places[0]?.device_short_name;
        const device_cycle_name = item.places[0]?.device_cycle_name;
        const device_name = `${device_short_name || '-'} / ${device_cycle_name || '-'}`;
        const orders = item.orders?.filter((order, index, self) => self.findIndex(t => t.id === order.id) === index) || [];
        const stock = item.current_stage?.data || null;

        let wasted_stage = item.wasted_stage || null;
        if (item.stages && item.stages.length > 0) {
            wasted_stage = item.stages[item.stages.length - 1].name;
        }
        return {
            id: item.id,
            date: date ? dayjs(date) : '-',
            device_name,
            device_short_name: device_short_name,
            device_place_name: item.places[0]?.device_place_name,
            device_cycle_name: device_cycle_name,
            device_place_position: item.places[0]?.device_place_position,
            place_name: (item.places[0]?.device_place_name || '-') + ' / ' + (item.places[0]?.device_place_position || '-'),
            scale_name: (item.places[0]?.scale?.scale || '-') + ' ' + (item.places[0]?.scale?.dimension || '-'),
            olig_name: item.oligonucleotid?.name || '-',
            ngs: item.ngs,
            olig_sequence: item.oligonucleotid?.sequence || '-',
            full_sequence: item.oligonucleotid?.full_sequence || '-',
            current_sequence: item.current_sequence || '-',
            work_sequence: item.current_oligonucleotide?.full_sequence || '-',
            target_sequence: item.oligonucleotid?.full_sequence || '-',
            modification_3: item.oligonucleotid?.modification_3 || '-',
            modification_5: item.oligonucleotid?.modification_5 || '-',
            modification_3_work: item.current_oligonucleotide?.modification_3 || '-',
            modification_5_work: item.current_oligonucleotide?.modification_5 || '-',
            types: types,
            length: item.oligonucleotid?.length || '-',
            places: item.places,
            reason: item.reason,
            comment: item.comment || '',
            stage_comment: item.stage_comment || '',
            inner: item.oligonucleotid?.inner || '-',
            quantity: item.quantity || null,
            orders,
            mkl, mkg,
            nanomole,
            e260, mw,
            '260_280': item['260_280'],
            '260_230': item['260_230'],
            operations: item.operations || [],
            stage_name: item.stage_name,
            purity: item.purity || '',
            nminus1: item.nminus1 || '',
            nplus1: item.nplus1 || '',
            link: item.link || '',
            additional: item.additional || '',
            conclusion: item.conclusion || '',
            prefix: item.prefix || '-',
            current_stage_id: item.current_stage?.id || '-',
            current_stage_name: item.current_stage?.name || '-',
            current_stage_date: item.current_stage?.date || '-',
            stages: item.stages,
            tablet_position: item.tablet_position,
            tablet_number: item.tablet_number,
            tablet_when: item.tablet_when,
            tablet_from: item.tablet_from,
            deblock: extractOperation(item, ServerOperations.DEBLOCK),
            remove_from_carrier: extractOperation(item, ServerOperations.REMOVE_FROM_CARRIER),
            evaporation: extractOperation(item, ServerOperations.EVAPORATION),
            seating: extractOperation(item, ServerOperations.SEATING),
            click: extractOperation(item, ServerOperations.CLICK),
            acylation: extractOperation(item, ServerOperations.ACYLATION),
            chromatography: extractOperation(item, ServerOperations.CHROMATOGRAPHY_VZH),
            chromatography_ion: extractOperation(item, ServerOperations.CHROMATOGRAPHY_ION),
            dissolation: extractOperation(item, ServerOperations.DISSOLUTION_FILTRATION),
            desalination: extractOperation(item, ServerOperations.DESALINATION),
            glenpack: extractOperation(item, ServerOperations.GLENPACK),
            mass: extractOperation(item, ServerOperations.TO_MASS_SPECTR),
            phoresis: extractOperation(item, ServerOperations.PHORESIS),
            elution: extractOperation(item, ServerOperations.ELUTION),
            mass_results: extractOperation(item, ServerOperations.MASS_RESULTS),
            detritylation: extractOperation(item, ServerOperations.DETRITYLATION),
            malonate: extractOperation(item, ServerOperations.REMOVE_MALONATE),
            stock,
            last_measurement: {...item.last_measurement, _all: [item.last_measurement]},
            measurements: item.measurements?.length > 0 ? {...item.measurements[0], _all: item.measurements} : null,
            wasted_date: item.finished_at || null,
            wasted_stage,
            previous_stage: item.previous_stage,
            mass_spectr_date: item.started_at || null,
        };
    });
    return mappedData;
}

export const filterOperationOptions = {
    hasntOperation: 1,
    hasNonCompleted: 2,
    hasOperation: 3,
    hasCompleted: 4,
    hasntNonCompleted: 5,
}

export const clickQueueColumns = [
    {
        id: 0, width: 100, title: 'Очередь', dataIndex: 'queue'
    },
    {
        id: 1, width: 150, title: 'Начало', dataIndex: 'started_at', render: function (v) {
            return dayjs(v).format('DD.MM.YYYY HH:mm')
        }
    },
    {
        title: 'Краска',
        width: 100,
        fieldName: 'paint_name',
        dataIndex: 'paint_name',
    },
    {
        id: 2, width: 150, title: 'Завершение', dataIndex: 'finished_at', render: function (v) {
            if (v) {
                return dayjs(v).format('DD.MM.YYYY HH:mm');
            } else {
                return '-';
            }
        }
    },
];

export const evaporationQueueColumns = [
    {
        id: 0, width: 100, title: 'Очередь', dataIndex: 'queue'
    },
    {
        id: 1, width: 150, title: 'Начало', dataIndex: 'started_at', render: function (v) {
            return dayjs(v).format('DD.MM.YYYY HH:mm')
        }
    },
    {
        id: 2, width: 150, title: 'Длительность', dataIndex: 'duration', render: function (v) {
            return duration(v);
        }
    },
    {
        id: 3, width: 150, title: 'Температура', dataIndex: 'temperature'
    },
    {
        id: 4, width: 150, title: 'Номер концентратора', dataIndex: 'concentratorNum'
    },
    {
        id: 5, width: 150, title: 'Завершение', dataIndex: 'finished_at', render: function (v) {
            if (v) {
                return dayjs(v).format('DD.MM.YYYY HH:mm');
            }
            return '-';
        }
    },
];


export const stringSorter = (field) => {
    return (a, b) => {
        return a[field].localeCompare(b[field]);
    }
}

export const numSorter = (field) => {
    return (a, b) => {
        return a[field] - b[field];
    }
}

export const ParamNames = {
    started_at: 'Начало',
    finished_at: 'Завершение',
    method: 'Метод',
    reactive: 'Реактив',
    t: 'Температура',
    time: 'Время',
    phase: 'Фаза',
    notes: 'Примечание',
    temperature: 'Температура',
    concentratorNum: 'Номер концентратора',
    position: 'Позиция',
    comment: 'Комментарий',
    concentration: 'Концентрация',
    cell_count: 'Кол-во ячеек',
    purity: 'Чистота, %',
    nminus1: 'N-1, %',
    nplus1: 'N+1, %',
    additional: 'Дополнительно',
    link: 'Ссылка на результат',
    conclusion: 'Заключение',
    tablet_from: 'Группа',
    tablet_when: 'Дата передачи',
    tablet_number: 'Номер планшета',
    tablet_position: 'Позиция на планшете',
    volume: 'Объём, мл',
    repeating: 'Повтор',
    device: 'Прибор',
    quantity: 'Количество',
    materials: 'Материалы',
    paint: 'Краска',
    paint_name: 'Краска',
    duration: 'Длительность',
    V: 'Краска, мкл',
    Y: 'ТЕАА, мкл',
    Q: 'СТОК, мкл',
    W: 'ДМСО, мкл',
    K: 'Аскорб. кислота, мкл',
    F: 'Вода, мкл',
    M: 'Общий объем, мкл',
    N: 'Конечная доля ДМСО, %',
    S: 'ЭДТА, мкл',
    a: 'Краска, мкл',
    b: 'ДМСО, мкл',
    c: 'Гидрокарбонат натрия, мкл',
    d: 'Уксусная кислота (ледяная), мкл',
    chromatograph: 'Хроматограф',
    chromeResult: 'Результат хроматографии',
    analysis_date: 'Дата анализа',
    mkl: 'МКЛ',
}
