import React, {forwardRef, useContext, useEffect, useRef, useState} from "react";
import PageHeader from "../../components/mock/PageHeader";
import {Button, Col, DatePicker, Form, Input, message, Row, Select, Skeleton, Space, Spin, Table, Upload} from "antd";
import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    SaveOutlined,
    UploadOutlined
} from "@ant-design/icons";
import locale from 'antd/es/date-picker/locale/ru_RU';
import TextArea from "antd/lib/input/TextArea";
import {PermissionsContext} from "../../lib/PermissionsContext";
import api, {format, getBearerAuthHeader} from "../../lib/util";
import dayjs from "dayjs";
import EditableRow from "./EditableRow";
import {useHotkeys} from "react-hotkeys-hook";
import {HotKeysContext} from "../../lib/HotKeyContext";
import {useHistory} from "react-router";
import {NotifyError} from "../../lib/notify";

const {Option} = Select;

const orderPriorities = [
    {value: 2, label: 'Обычный'},
    {value: 3, label: 'Высокий'},
    {value: 4, label: 'Критичный'},
]

const orderRevisionStatuses = [
    {value: 1, label: 'Не требует сверки'},
    {value: 2, label: 'Ожидает сверки'},
    {value: 3, label: 'Сверка проведена', disabled: true},
];

const orderStatuses = [
    {value: 1, label: 'В ожидании'},
    {value: 2, label: 'В производстве'},
    {value: 3, label: 'Завершен', disabled: true},
    {value: 4, label: 'Отменен'},
];

export const getOrderPriority = (priority) => {
    const priorityObj = orderPriorities.find((item) => item.value === priority);
    return priorityObj ? priorityObj.label : '';
}

export const getOrderRevisionStatuses = (status) => {
    const statusObj = orderRevisionStatuses.find((item) => item.value === status);
    return statusObj ? statusObj.label : '';
}

export const getOrderStatus = (status) => {
    const statusObj = orderStatuses.find((item) => item.value === status);
    return statusObj ? statusObj.label : '';
}

function getSubstanceType(value) {
    switch (value) {
        case 1:
            return 'Раствор';
        case 2:
            return 'Лиофилизат';
        default:
            return '-';
    }
}

function getNGS(value) {
    switch (value) {
        case true:
            return 'Да';
        case false:
            return 'Нет';
        default:
            return '-';
    }
}

const OrderEdit = (props) => {

    const [oligs, setOligs] = useState([]);

    const orderId = props.match?.params?.id || null;
    const title = orderId ? "Редактирование заказа" : "Создание заказа";
    const [clients, setClients] = useState([]);
    const [contacts, setContacts] = useState([]);

    const [form] = Form.useForm();
    const [oligForm] = Form.useForm();

    const {hasPermission} = useContext(PermissionsContext);
    const {setHotKeysEnabled} = useContext(HotKeysContext);
    const [orderStatus, setOrderStatus] = useState();
    const [orderRevisionStatus, setOrderRevisionStatus] = useState();

    const [loading, setLoading] = useState({current: true});
    const [editingKey, setEditingKey] = useState(null);
    const [prevOlig, setPrevOlig] = useState(null);
    const [activeColumn, setActiveColumn] = useState(null);
    const [importing, setImporting] = useState(false);
    const [saving, setSaving] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const tableRef = useRef();

    const canEdit = hasPermission('orders.edit') && orderStatus !== 3 && orderStatus !== 3;

    const getOrderStatus = () => {
        return orderStatuses.filter((item) => item.hidden !== true);
    }

    const getOrderRevisionStatuses = () => {
        return orderRevisionStatuses.filter((item) => item.hidden !== true);
    }

    const [rowErrors, setRowErrors] = useState({});

    const isEditing = (record) => record.id === editingKey;
    const history = useHistory();

    const handleEdit = (record, columnKey) => {
        if (editingKey !== null || !canEdit) {
            return;
        }
        setEditingKey(record.id);
        setPrevOlig({...record});
        setActiveColumn(columnKey);
        oligForm.setFieldsValue({...record});
    };

    const handleCancelEdit = () => {

        let newOligs = [];
        if (prevOlig) {
            newOligs = oligs.map((item) => {
                if (item.id === prevOlig.id) {
                    return prevOlig;
                }
                return item;
            });
        } else {
            newOligs = oligs.filter((item) => item.id !== editingKey);
        }
        setOligs(newOligs);
        setEditingKey(null);
        setActiveColumn(null);
    };

    const handleDelete = (record) => {
        const newOligs = oligs.filter((item) => item.id !== record.id);
        setOligs(newOligs);
        setEditingKey(null);
        const newErrors = {...rowErrors};
        delete newErrors[record.id];
        setRowErrors(newErrors);
    }

    useEffect(() => {
        setHotKeysEnabled(editingKey === null);
    }, [editingKey]);

    useHotkeys('esc', (e) => {
        setEditingKey(null);
    }, [editingKey]);

    const scrollToRow = (index: number) => {
        const rowHeight = 54;
        const scrollTop = index * rowHeight;
        tableRef.current?.scrollTo({
            top: scrollTop,
            behavior: 'smooth' // или 'auto' для мгновенной прокрутки
        });
    }

    const columnToFieldMap = {
        name: 'name',
        full_sequence: 'full_sequence',
        quantity: 'quantity',
        ngs: 'ngs',
        substance_type_id: 'substance_type_id',
        comment: 'comment'
    };

    const getColumns = () => {

        const columns = [
            {
                id: 1,
                title: '№',
                dataIndex: 'index',
                key: 'index',
                width: 75,
                render: (value, record, index) => page > 1 ? (index + 1) + (page - 1) * pageSize : index + 1
            },
            {
                id: 2, title: 'Наименование', dataIndex: 'name', key: 'name', width: 200,
                render: (value, record, index) => {
                    const error = rowErrors[index]?.name;
                    if (error) {
                        return <Space direction={'vertical'}>
                            <div style={{maxWidth: 190, overflow: 'hidden'}}>{record.name}</div>
                            <div style={{color: 'red', maxWidth: 190}}>{error}</div>
                        </Space>
                    } else {
                        return record.name;
                    }
                }
            },
            {
                id: 3, title: 'Тип', dataIndex: 'types', key: 'type', width: 260,
                render: (value, record) => {
                    if (Array.isArray(value)) {
                        return value.map((item) => item.name).join(', ');
                    } else {
                        return '-';
                    }
                }
            },
            {id: 4, title: 'Длина', dataIndex: 'length', key: 'length', width: 80, align: 'center'},
            {
                id: 5, title: 'Последовательность', dataIndex: 'full_sequence', key: 'full_sequence',
                render: (value, record, index) => {
                    const error = rowErrors[index]?.full_sequence;
                    if (error) {
                        return <Space direction={'vertical'}>
                            <div style={{
                                width: 400,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>{record.full_sequence}</div>
                            <div style={{color: 'red'}}>{error}</div>
                        </Space>
                    } else {
                        return <div style={{
                            width: 400,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>{record.full_sequence}</div>;
                    }
                }
            },
            {id: 6, title: '5\'', dataIndex: 'modification_5', key: 'modification_5', width: 80},
            {id: 7, title: '3\'', dataIndex: 'modification_3', key: 'modification_3', width: 80},
            {
                id: 8, title: 'Кол-во', dataIndex: 'quantity', key: 'quantity', width: 80, align: 'center',
                render: (value, record) => {
                    return format(record.quantity || record.pivot?.quantity);
                }
            },
            {
                id: 9, title: 'NGS', dataIndex: 'ngs', key: 'ngs', width: 110, align: 'center',
                render: (value, record) => {
                    return getNGS(record.ngs || record.pivot?.ngs || false);
                }
            },
            {
                id: 10, title: 'Форма поставки', dataIndex: 'substance_type_id', key: 'substance_type_id', width: 110,
                render: (value, record) => {
                    return getSubstanceType(record.substance_type_id || record.pivot.type);
                }
            },
            {
                id: 11, title: 'Комментарий', dataIndex: 'comment', key: 'comment', width: 200,
                render: function (value, record) {
                    let comment = record?.comment || record?.pivot?.comment || '';
                    let shortComment = comment.substring(0, 25);
                    return <div title={comment}>{shortComment}</div>
                }
            },
        ].map(col => ({
            ...col,
            onCell: (record) => ({
                onClick: (e) => {
                    // Проверяем, что колонка редактируемая
                    if (columnToFieldMap[col.dataIndex] && !isEditing(record)) {
                        if (canEdit) {
                            e.stopPropagation();
                            handleEdit(record, columnToFieldMap[col.dataIndex]);
                        }
                    }
                }
            })
        }));
        columns.push(
            {
                id: 12,
                title: '',
                dataIndex: 'edit',
                key: 'edit',
                width: 85,
                render: (_, record) => {
                    return <Space>
                        <Button
                            size="small"
                            shape="circle"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleEdit(record, 'name'); // По умолчанию фокус на name при клике на кнопку edit
                            }}
                            disabled={(editingKey !== null && !isEditing(record)) || !canEdit}
                        >
                            {isEditing(record) ? <CheckOutlined/> : <EditOutlined/>}
                        </Button>
                        <Button size="small" shape="circle" onClick={(e) => {
                            e.stopPropagation();
                            handleDelete(record);
                        }} disabled={!!editingKey || !canEdit}>
                            <DeleteOutlined/>
                        </Button>
                    </Space>;
                }
            }
        );
        return columns;
    }

    const checkCreationDate = async (field, value) => {
        return new Promise((resolve, reject) => {
            if (value && dayjs(value).isAfter(dayjs())) {
                return reject(new Error('Дата заказа не может быть больше текущей'));
            } else {
                return resolve();
            }
        })
    }

    const checkExecutionDate = async (field, value) => {
        const created_at = form.getFieldValue('created_at');
        return new Promise((resolve, reject) => {
            if (value && created_at && dayjs(value).isBefore(dayjs(created_at))) {
                return reject(new Error('Дата выполнения не может быть меньше даты заказа'));
            } else {
                return resolve();
            }
        })
    }

    const loadOrder = () => {
        return new Promise((resolve, reject) => {
            if (orderId) {
                api.getJSON(`/api/order/${orderId}`).then((resp) => {
                    resp.created_at = dayjs(resp.created_at);
                    resp.deadline_at = resp.deadline_at ? dayjs(resp.deadline_at) : null;
                    form.setFieldsValue(resp);
                    let oligs = resp.oligonucleotids.map((item) => {
                        return {
                            ...item,
                            comment: item.pivot.comment,
                            ngs: item.pivot.ngs,
                            substance_type_id: item.pivot.type,
                            quantity: item.pivot.quantity,
                        };
                    });
                    setOligs(oligs);
                    setOrderStatus(resp.order_status_id);
                    setOrderRevisionStatus(resp.revision_status_id);
                    resolve(resp);
                }).catch((error) => {
                    console.error(error);
                    reject(error);
                });
            } else {
                return resolve();
            }
        });
    }

    const loadClients = () => {
        return new Promise((resolve, reject) => {
            api.getJSON('/api/client/all').then((resp) => {
                setClients(resp);
                resolve(resp);
            }).catch((error) => {
                console.error(error);
                reject(error);
            });
        });
    }

    const loadContacts = (userId) => {
        return api.getJSON(`/api/user/${userId}/contacts`).then((resp) => {
            setContacts(resp?.data || []);
        }).catch((error) => {
            console.error(error);
        });
    }

    useEffect(() => {
        setTimeout(() => {
            const userId = form.getFieldValue('user_id');
            if (userId) {
                console.log('load contacts for user', userId);
                loadContacts(userId).then(() => {
                    const personId = form.getFieldValue('user_contact_id')
                    if (personId) {
                        form.setFieldsValue({user_contact_id: personId});
                    }
                });
            }
        }, 200);
    }, [clients, form]);

    const getNextRowId = () => {
        const maxId = Math.max(...oligs.map((item) => item.id), 0);
        return maxId + 1;
    }

    useEffect(() => {
        setLoading(true);
        Promise.all([loadOrder(), loadClients()]).then(() => {
            const userId = form.getFieldValue('user_id');
            if (userId) {
                loadContacts(userId).then(() => {
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }
        }).catch((error) => {
            console.error(error);
            setLoading(false);
        });
    }, []);

    const submitMainForm = () => {
        if (!canEdit) return;
        setSaving(true);
        setRowErrors({});
        if (oligs.length === 0) {
            message.error('Список заказа не может быть пустым');
        }
        oligForm.validateFields().then((values) => {
            form.validateFields().then((values) => {
                const formData = form.getFieldsValue();
                const _oligs = oligs.map((item) => {
                    return {
                        ...item,
                        type: item.substance_type_id,
                    }
                });
                const order = {
                    ...formData,
                    id: orderId,
                    oligonucleotide: _oligs,
                }
                const url = orderId ? `/api/order/update/${orderId}` : '/api/order/store';
                api.postJSON(url, order).then((resp) => {
                    const id = resp.id;
                    history.push(`/order/edit/${id}`);
                    message.success('Заказ сохранен', 2);
                    setSaving(false);
                }).catch((resp) => {
                    const messages = [];
                    const _rowErrors = {};
                    if (resp.errors) {
                        Object.keys(resp.errors).forEach((key) => {
                            messages.push(resp.errors[key][0]);
                            const parts = key.split('.');
                            if (parts.length === 3) {
                                const rowId = parseInt(parts[1]);
                                if (!_rowErrors[rowId]) {
                                    _rowErrors[rowId] = {};
                                }
                                _rowErrors[rowId][parts[2]] = resp.errors[key][0];
                            }
                        });
                        setRowErrors(_rowErrors);
                        if (Object.keys(_rowErrors).length > 0) {
                            const firstRowIndex = Object.keys(_rowErrors)[0];
                            scrollToRow(firstRowIndex);
                        }
                    }
                    message.error('Ошибка при сохранении заказа: ' + messages.join(', '), 2);
                    setSaving(false);
                });
            }).catch((info) => {
                console.log('info', info);
                setSaving(false);
            });
        }).catch((info) => {
            console.log('info', info);
            setSaving(false);
        });
    }

    const onUploadFile = (info) => {
        const {status} = info.file;
        if (status === 'done') {
            setImporting(false);
            const {response} = info.file;
            let nextId = 1;
            if (oligs.length > 0) {
                nextId = getNextRowId();
            }
            if (response && response.items) {
                const errors = {};
                const _oligs = response.items.map((item, index) => {
                    const id = nextId++;
                    if (item.error) {
                        errors[id] = item.error_message;
                    }
                    return {
                        ...item,
                        id: id,
                        ngs: item.ngs || false,
                        quantity: parseFloat(item.quantity) || 1,
                        substance_type_id: item.olig_type,
                        comment: item?.comment || '',
                    };
                });
                setOligs([...oligs, ..._oligs]);
                setRowErrors(errors);
                if (Object.keys(errors).length > 0) {
                    console.log('errors', errors);
                    NotifyError('Ошибка при импорте', 'Проверьте правильность данных в файлах');
                }
            }
        } else if (status === 'error') {
            setImporting(false);
        }
    }

    const handleSaveRow = (record) => {
        oligForm.validateFields().then((values) => {
            let newOligs = oligs.map((item) => {
                if (item.id === record.id) {
                    return {...item, ...record};
                }
                return item;
            });
            setOligs(newOligs);
            setEditingKey(null);
            const newErrors = {...rowErrors};
            delete newErrors[record.id];
            setRowErrors(newErrors);
        });
    }

    // Компонент для строки (оборачиваем в forwardRef)
    const CustomRow = forwardRef((props, ref) => {
        const {children} = props;

        if (children?.[0]?.props) {
            const {record} = children[0].props;

            if (isEditing(record)) {
                return (
                    <EditableRow
                        {...props}
                        ref={ref} // Добавляем ref, чтобы избежать ошибки
                        record={record}
                        onSave={handleSaveRow}
                        onCancel={handleCancelEdit}
                        form={oligForm}
                        activeColumn={activeColumn}
                    />
                );
            }
            const rowStype = rowErrors[record.id] ? {
                backgroundColor: 'rgba(255, 0, 0, 0.1)',
                position: 'relative'
            } : {};

            return (
                <>
                    <div {...props} style={rowStype} ref={ref} onClick={() => handleEdit(record)}>
                        {children}
                        {rowErrors[record.id] && <div style={{
                            position: 'absolute',
                            color: 'red',
                            bottom: 0,
                            fontSize: 10
                        }}>{rowErrors[record.id]}</div>}
                    </div>
                </>
            );
        }
    });

    const components = {
        body: {
            row: CustomRow, // Используем исправленный компонент
        },
    };


    const addOlig = () => {
        const newOlig = {
            id: getNextRowId(),
            sequence: '',
            quantity: '1',
            ngs: form.getFieldValue('ngs_default_value'),
            substance_type_id: form.getFieldValue('olig_default_type_id'),
            pivot: {
                quantity: '1',
            }
        };
        setPrevOlig(null);
        setOligs([...oligs, newOlig]);
        setEditingKey(newOlig.id);
        oligForm.resetFields();
    }

    const changeDefaultOligType = (value) => {
        const newOligs = oligs.map((item) => {
            return {...item, substance_type_id: value};
        });
        setOligs(newOligs);
    }

    const changeDefaultOligNGS = (value) => {
        const newOligs = oligs.map((item) => {
            return {...item, ngs: value};
        });
        setOligs(newOligs);
    }

    const submitBtns = <Row align={'end'} style={{marginTop: 20}}>
        <Skeleton loading={loading}>
            <Space direction="horizontal" align={"center"}>
                {canEdit && <Button disabled={!!editingKey || saving || !!Object.keys(rowErrors).length} type="primary"
                                    icon={<SaveOutlined/>}
                                    htmlType={"button"}
                                    onClick={submitMainForm}>Сохранить</Button>}
                <Button type="secondary" htmlType={"button"} icon={<CloseOutlined/>}
                        href={"/order"}>Закрыть</Button>
            </Space>
        </Skeleton>
    </Row>;

    return <>
        <PageHeader title={title} showFavorite={false}/>
        <Spin spinning={saving}>
            <div style={{background: '#fff', padding: '6px 24px', minHeight: 280}}>
                <Form form={form} onFinish={submitMainForm} className={'order-form'}
                      disabled={!canEdit || editingKey}
                >
                    {submitBtns}
                    <Row gutter={20} style={{marginTop: 20}}>
                        <Col span={8}>
                            <Skeleton loading={loading}>
                                <Form.Item label="Дата заказа" name="created_at" labelCol={{span: 12}}
                                           initialValue={dayjs()}
                                           rules={[{required: true, message: 'Введите дату заказа'},
                                               {validator: checkCreationDate}]}
                                >
                                    <DatePicker locale={locale} format="DD.MM.YYYY"/>
                                </Form.Item>
                                <Form.Item label="Плановая дата завершения работ (поставки)" name="deadline_at"
                                           labelCol={{span: 12}}
                                           rules={[{validator: checkExecutionDate}]}>
                                    <DatePicker locale={locale} format="DD.MM.YYYY"/>
                                </Form.Item>
                                <Form.Item label="Номер заказа" name="order_number" labelCol={{span: 12}} required
                                           rules={[{required: true, message: 'Введите номер заказа'}]}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item label="Номер заказа клиента" name="order_client_number"
                                           labelCol={{span: 12}}>
                                    <Input/>
                                </Form.Item>
                                <Form.Item label="Номер заявки" name="order_request_number" labelCol={{span: 12}}>
                                    <Input/>
                                </Form.Item>
                            </Skeleton>
                        </Col>
                        <Col span={8}>
                            <Skeleton loading={loading}>
                                <Form.Item label="Клиент" name="user_id" labelCol={{span: 5}} required
                                           rules={[{required: true, message: 'Выберите клиента'}]}
                                           initialValue={form.getFieldValue('user_id')}
                                >
                                    <Select showSearch placeholder="Выберите клиента"
                                            onChange={(value) => {
                                                form.setFieldsValue({user_id: value, user_contact_id: null});
                                                loadContacts(value);
                                            }}
                                            filterOption={function (input, option) {
                                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }}
                                    >
                                        {clients.map(function (item) {
                                            return <Option value={item.id}
                                                           key={item.id}>{item.organization}</Option>;
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Контакное лицо" name="user_contact_id" labelCol={{span: 5}}
                                           initialValue={form.getFieldValue('user_contact_id')}
                                >
                                    <Select showSearch placeholder="Выберите контактное лицо"
                                            onChange={(value) => {
                                                form.setFieldsValue({user_contact_id: value});
                                            }}
                                            filterOption={function (input, option) {
                                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }}
                                    >
                                        {contacts.map(function (item) {
                                            return <Option value={item.id}
                                                           key={item.id}>{item.name}</Option>;
                                        })}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Тип заказа" name="order_type_id"
                                           rules={[{required: false, message: 'Поле Тип заказа обязательное'}]}
                                           initialValue={1} labelCol={{span: 5}}>
                                    <Select placeholder="Тип заказа">
                                        <Option value={1}>Внешний заказ</Option>
                                        <Option value={2}>Внешний пробный заказ</Option>
                                        <Option value={3}>Внутренний заказ</Option>
                                        <Option value={4}>Рекламация</Option>
                                        <Option value={5}>Эксперимент</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item label="Статус заказа" name="order_status_id" labelCol={{span: 5}}
                                           initialValue={1}
                                >
                                    <Select placeholder="Статус заказа" options={getOrderStatus()}/>
                                </Form.Item>
                                <Form.Item label="Статус сверки" name="revision_status_id" labelCol={{span: 5}}
                                           initialValue={2}
                                >
                                    <Select placeholder="Статус сверки" options={getOrderRevisionStatuses()}/>
                                </Form.Item>
                            </Skeleton>
                        </Col>
                        <Col span={8}>
                            <Skeleton loading={loading}>
                                <Form.Item label="Приоритет" name="priority" labelCol={{span: 5}}
                                           initialValue={2}
                                >
                                    <Select placeholder="Приоритет" options={orderPriorities}/>
                                </Form.Item>
                                <Form.Item label="Комментарий" name="comment" labelCol={{span: 5}}>
                                    <TextArea disabled={!!editingKey} size={'large'} rows={2}/>
                                </Form.Item>
                                <Form.Item label="Форма поставки" name="olig_default_type_id" labelCol={{span: 5}}
                                           initialValue={1}
                                >
                                    <Select placeholder="Форма поставки" onChange={changeDefaultOligType}>
                                        <Option key={1} value={1}>Раствор</Option>
                                        <Option key={2} value={2}>Лиофилизат</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="NGS" name="ngs_default_value" labelCol={{span: 5}}
                                           initialValue={false}>
                                    <Select placeholder="NGS" onChange={changeDefaultOligNGS}>
                                        <Option key={1} value={true}>Да</Option>
                                        <Option key={2} value={false}>Нет</Option>
                                    </Select>
                                </Form.Item>
                            </Skeleton>
                        </Col>
                    </Row>
                </Form>
                <Row style={{marginTop: 20, marginBottom: 20}}>
                    <Col span={24} style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Skeleton loading={loading}>
                            <Upload method={'post'}
                                    disabled={!canEdit || !!editingKey}
                                    name='file'
                                    multiple={false}
                                    headers={getBearerAuthHeader()}
                                    action={`/api/oligonucleotid/import`}
                                    onChange={(info) => onUploadFile(info)}
                                    onDrop={(e) => e.preventDefault()}
                            >
                                <Button disabled={!!editingKey || !canEdit} type="dashed"><UploadOutlined/> Импорт
                                    из
                                    файла</Button>
                            </Upload>
                            <Button disabled={!canEdit || !!editingKey} type="dashed"
                                    onClick={addOlig}><PlusOutlined/> Добавить олиг</Button>
                        </Skeleton>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Skeleton loading={loading}>
                            <Form form={oligForm} style={{marginBottom: 0}}>
                                <Table rowKey={'id'}
                                       ref={tableRef}
                                       components={components}
                                       columns={getColumns()}
                                       dataSource={oligs}
                                       pagination={false}
                                       size={"large"}
                                       virtual
                                       scroll={{y: 450}}
                                       style={{height: 500, maxHeight: 500}}
                                       className={'order-table'}
                                       rowClassName={'order-row'}
                                       onRow={(record) => ({
                                           onClick: () => handleEdit(record, 'name'),
                                       })}
                                />
                            </Form>
                        </Skeleton>
                    </Col>
                </Row>
            </div>
        </Spin>
    </>;
}

export default OrderEdit;

