import React, {createRef, useEffect, useState} from "react";
import {Col, Row, Table} from "antd";
import '../../assets/Deblock.scss';
import TableCustomizer, {loadColumnSettings} from "../../components/TableCustomizer";
import CTable from "../../components/CTable";

export function StageData(props) {

    const ref = createRef();
    const [scrollY, setScrollY] = useState(0);
    const [popup, setPopup] = useState({record: null, visible: false, x: 0, y: 0});
    const [showColumnsEditor, setShowColumnsEditor] = useState(false);

    function onSelectChange(keys) {
        let data = keys.map((item) => {
            return props.data.filter(item1 => item1.id === item)[0]
        })
        props.onSelectRow(data);
    }

    const getSelectedRowKeys = () => {
        let result = props.selectedRows ? props.selectedRows.map(item => item.id) : [];
        return result;
    }

    const scroll = {
        y: scrollY
    }

    useEffect(() => {
        if (ref.current) {
            const posOfTable = ref.current.getBoundingClientRect();
            const clientHeight = document.documentElement.clientHeight;
            const offsetY = !!props.pagination ? 146 : 86;
            setScrollY(clientHeight - posOfTable.top - offsetY);
        }
    }, [props.data]);

    const renderSelectedRows = () => {
        const selectedRows = document.getElementsByClassName('selected-rows');
        if (selectedRows.length > 0) {
            selectedRows[0].remove();
        }
        if (props.selectedRows && props.selectedRows.length > 0) {
            const ref = document.querySelector('.data-table.ant-table-wrapper');
            if (ref) {
                const div = document.createElement('div');
                div.className = 'selected-rows';
                div.innerHTML = props.selectedRows.length;
                ref.appendChild(div);
            }
        }
    }

    const getStageUrl = () => {
        if (props.stage) {
            return props.stage;
        }
        return window.location.pathname.split('/').pop();
    }

    return <>
        <Row style={{marginTop: '0px'}}>
            <Col span={24} ref={ref}>
                <CTable rowKey="id"
                        dataSource={props.data}
                        ref={ref}
                        bordered
                        size={'small'}
                        scroll={scroll}
                        loading={props.loading}
                        rowSelection={{
                            selectedRowKeys: getSelectedRowKeys(),
                            onChange: onSelectChange,
                        }}
                        pagination={props.pagination ? props.pagination : false}
                        onChange={props.onDataTableChange ? props.onDataTableChange : null}
                        columns={props.columns}
                        tableName={getStageUrl()}
                />
            </Col>
        </Row>
        {renderSelectedRows()}
        {showColumnsEditor && <TableCustomizer stage={getStageUrl()} initialColumns={props.columns} open={true}
                                               onHide={() => setShowColumnsEditor(false)}/>}
    </>

}
