import React, {useEffect} from "react";
import PageHeader from "../../components/mock/PageHeader";
import api, {downloadFileWithPOST} from "../../lib/util";
import {Button, Col, Form, Row, Select, Table} from "antd";
import {isObject} from "underscore";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {getOperations, operations} from "./Shared";

const renderMaterials = (materials) => {
    if (Array.isArray(materials)) {
        return <table>
            <thead>
            <tr>
                <th>Название</th>
                <th>Кат. №</th>
                <th>Партия</th>
                <th>Производитель</th>
            </tr>
            </thead>
            <tbody>
            {materials.map((item, index) => {
                return <tr key={index}>
                    <td>{item.material_name}</td>
                    <td>{item.catalogue_number}</td>
                    <td>{item.batch_number}
                    </td>
                    <td>{item.manufacturer}</td>
                </tr>;
            })}
            </tbody>
        </table>
    } else {
        return '';
    }
}

const PortionHistory = (props) => {

    const [loading, setLoading] = React.useState(false);

    const [portion, setPortion] = React.useState([]);
    const [filterStage, setFilterStage] = React.useState([]);
    const [events, setEvents] = React.useState([]);
    const [filterOperation, setFilterOperation] = React.useState([]);
    const [allExpanded, setAllExpanded] = React.useState(false);
    const [operationTypeId, setOperationTypeId] = React.useState(null);
    const [params, setParams] = React.useState({});

    const load = () => {
        setLoading(true);
        api.getJSON(`/api/portion/history-by-events/${props.match.params.id}`).then((data) => {
            const portion = data;
            const portionData = {
                portion_id: portion.id,
                olig_name: portion.oligonucleotid.full_sequence,
                prefix: portion.prefix,
                oligonucleotid: portion.oligonucleotid,
                device_place_name: portion.device_place_name,
                device_place_position: portion.device_place_position,
                types: portion.oligonucleotid?.types?.map(item => item.name).join(', '),
                nanomole: portion.nanomole,
                quantity: portion.oligonucleotid.quantity,
            };
            setPortion(portionData);
            const e = parseEvent(data.events);
            setEvents(e);
        }).finally(() => {
            setLoading(false);
        });
    }

    const parseEvent = (data) => {
        return data.map((item, index) => {
            return {
                index: ++index,
                id: item.id,
                name: item.name,
                created_at: item.created_at,
                created_by: item.author.name,
                events: item.events && item.events.length > 0 ? parseEvent(item.events) : [],
                params: item.params,
                payload: item.payload,
                materials: item.materials,
                portionToOperationId: item.portionToOperationId,
            }
        });
    }

    const renderEvents = (events) => {
        if (!events) {
            return '';
        }
        return events.map((item) => {
            return <div key={item.id}>
                <div>{item.name}</div>
                <div>{item.created_at}</div>
                <div>{item.author.name}</div>
                <div>{item.data}</div>
                <div>{renderEvents(item.events)}</div>
            </div>;
        });
    }

    const editEvent = (portion) => {
        const operationType = portion.id;
        let params = portion.payload;
        params.materials = portion.materials || [];
        params.portionToOperationId = portion.portionToOperationId;
        setParams(params);
        setTimeout(() => {
            setOperationTypeId(operationType);
        }, 100);
    }

    const deleteEvent = (event) => {
        console.log('deleteEvent', event);
    }

    const getColumns = () => [
        {dataIndex: 'index', title: '№', width: 40},
        {dataIndex: 'name', title: 'Название', width: 200},
        {
            dataIndex: 'created_at', title: 'Дата', width: 200, render: (value) => {
                return value ? new Date(value).toLocaleString() : '';
            }
        },
        {
            dataIndex: 'created_by',
            title: 'Пользователь',
            width: 200,
            render: (value, record) => {
                return value;
            }
        },
        {
            dataIndex: 'params',
            title: 'Данные',
            render: (params) => {
                if (!params || !isObject(params)) {
                    return;
                }
                return Object.keys(params).map((key, index) => {
                    if (!params[key]) {
                        return '';
                    }
                    if (key === 'Файлы' && params[key]) {
                        return params[key].map(item => {
                            return <div key={index}><strong>{key}</strong>: <Button onClick={function () {
                                downloadFileWithPOST('/api/file/' + item);
                            }}>Скачать</Button></div>;
                        });
                    }
                    return <div key={index}><strong>{key}</strong>: {params[key]}</div>
                });
            }
        },
        {
            dataIndex: 'actions',
            title: 'Действия',
            render: (_, record) => {
                if (record.id) {
                    return <div>
                        <Button icon={<EditOutlined/>} size={'small'} onClick={() => editEvent(record)}/>
                        <Button icon={<DeleteOutlined/>} size={'small'} onClick={() => deleteEvent(record)}/>
                    </div>;
                }
            }
        }
    ];

    useEffect(() => {
        const id = props.match.params.id;
        if (id) {
            load();
        }
    }, []);

    const renderPortionHeader = () => {
        return <table style={{width: '100%'}}>
            <thead>
            <tr>
                <th align={'left'}>ID</th>
                <th align={'left'}>Название</th>
                <th align={'left'}>Префикс</th>
                <th align={'left'}>Рабочая последовательность</th>
                <th align={'left'}>Целевая последовательность</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td align={'left'}>{portion?.portion_id}</td>
                <td align={'left'}>{portion?.olig_name || '-'}</td>
                <td align={'left'}>{portion?.prefix || '-'}</td>
                <td align={'left'}>{portion?.work_sequence}</td>
                <td align={'left'}>{portion?.target_sequence}</td>
            </tr>
            </tbody>
        </table>;
    }
    const expandableProps = {
        columnWidth: 48,
        expanded: true,
        isExpanded: true,
        expandedRowRender: (record, index) => {
            return <Row style={{margin: 5, marginLeft: 48}} key={index}>
                <Col span={24}>
                    <Table rowKey={'index'} columns={getColumns()} dataSource={record.events} pagination={false}/>
                </Col>
            </Row>;
        },
        rowExpandable: (record) => record.events?.length > 0,
        defaultExpandAllRows: true,
        showExpandColumn: true,
    };

    return <>
        {operationTypeId && getOperations([
            {
                id: operationTypeId, params: params, ...portion, portionToOperationId: params.portionToOperationId
            }
        ], operationTypeId, () => {
            setOperationTypeId(null);
        })}
        <PageHeader title={"История пробирки"} showFavorite={false}/>
        operation type ID: {operationTypeId}
        <Row style={{margin: 24}}>
            <Col span={20}>
                <Form layout={'inline'}>
                    <Form.Item label={"Этап"}>
                        <Select value={filterStage} options={[]} style={{width: 400}}
                                onChange={(value) => {
                                    setFilterStage(value);
                                }}
                                allowClear={true}
                                mode={'multiple'}
                        />
                    </Form.Item>
                    <Form.Item label={"Операция"}>
                        <Select value={filterOperation} options={[]} style={{width: 800}}
                                onChange={(value) => {
                                    setFilterOperation(value);
                                }}
                                allowClear={true}
                                mode={'multiple'}
                        />
                    </Form.Item>
                </Form>
            </Col>
            <Col span={4} style={{display: 'flex', justifyContent: 'end'}}>
                <Button onClick={() => {
                    setAllExpanded(!allExpanded);
                }}>{allExpanded ? 'Свернуть все' : 'Развернуть все'}
                </Button>
            </Col>
        </Row>
        <Row style={{margin: 24}}>
            <Col span={24}>
                {renderPortionHeader()}
            </Col>
        </Row>
        <Row style={{margin: 24}}>
            <Col span={24}>
                <Table rowKey={"index"} columns={getColumns()} dataSource={events}
                       loading={loading} pagination={false}
                       expandable={expandableProps}
                       className={'bold-header'}
                       scroll={{x: 2000}}
                />
            </Col>
        </Row>
    </>;

}
export default PortionHistory;
