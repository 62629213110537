import {Button, Col, Form, Input, Row, Select} from "antd";
import PageHeader from "../../components/mock/PageHeader";
import api, {arraysEqual} from "../../lib/util";
import {useEffect, useRef, useState} from "react";
import {StageData} from "./StageData";
import {getOperations} from "./Shared";
import {NotifyError} from "../../lib/notify";
import PropTypes from "prop-types";
import {PrinterOutlined} from "@ant-design/icons";
import debounce from "lodash/debounce";
import dayjs from "dayjs";

const {Option, OptGroup} = Select;

const zip = (arr1, arr2) => {
    return arr1.map((k, i) => [k, arr2[i] || null]);
}

function StageManager(props) {

    const [data, setData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const loading = useRef(false);
    const [searchString, setSearchString] = useState(props.initialSearchString);
    const [selectedRows, setSelectedRows] = useState(props.selectedRows);
    const [activeOperation, setActiveOperation] = useState(null);
    const [types, setTypes] = useState([]);
    const [filterTypes, setFilterTypes] = useState([]);
    const [loadLabels, setLoadLabels] = useState(false);
    const prevValues = useRef({});


    function load() {
        return new Promise((resolve, reject) => {
                let url = props.url;
                let queryParams = [];
                if (props.queryParams) {
                    for (let key in props.queryParams) {
                        if (Array.isArray(props.queryParams[key])) {
                            for (let value of props.queryParams[key]) {
                                queryParams.push(key + '[]=' + value);
                            }
                        } else {
                            if (key.match(/^\d+$/)) {
                                queryParams.push(props.queryParams[key]);
                            } else {
                                queryParams.push(key + '=' + props.queryParams[key]);
                            }
                        }
                    }
                }
                if (searchString) {
                    queryParams.push('search=' + searchString);
                }
                if (filterTypes) {
                    filterTypes.map((type) => queryParams.push('types[]=' + type));
                }
                if (queryParams) {
                    url += '?' + queryParams.join('&');
                }
                api.getJSON(url).then(result => {
                    if (props.onLoad) {
                        props.onLoad(result);
                    }
                    if (props.mapFucntion) {
                        result = props.mapFucntion(result);
                    }
                    resolve(result);
                }).catch(error => {
                    console.log('error', error);
                    NotifyError(error.message);
                }).finally(() => {
                    // убрал согласно задаче GEN-736, но потом возможно надо будет вернуть
                    // setSelectedRows([]);
                });
            }
        );
    }

    function onSearch(e) {
        setSearchString(e.target.value);
    }

    useEffect(() => {
        if (!loading.current) {
            if (arraysEqual(prevValues.current.queryParams, props.queryParams) &&
                arraysEqual(prevValues.current.onlyIds, props.onlyIds) &&
                prevValues.current.searchString === searchString &&
                arraysEqual(prevValues.current.filterTypes, filterTypes)) {
                return;
            }
            loading.current = true;
            setLoaded(false);
            load().then((result) => {
                setData(result);
                setLoaded(true);
                loading.current = false;
                prevValues.current = {
                    queryParams: props.queryParams,
                    onlyIds: props.onlyIds,
                    searchString: searchString,
                    filterTypes: filterTypes
                };
            }).finally(() => {
                loading.current = false;
            });
        }
    }, [props.queryParams, props.onlyIds, searchString, filterTypes]);

    function loadTypes() {
        api.getJSON('/api/oligonucleotide-type').then((result) => {
            setTypes(result);
        });
    }

    useEffect(() => {
        loadTypes();
    }, []);

    function onFilterHandler(filter) {
        console.log('filter', filter);
    }

    function refresh() {
        setLoaded(false);
        let _actOp = activeOperation;
        let _selectedIds = getSelectedRowKeys();
        setActiveOperation(null);
        setSelectedRows([]);
        load().then((result) => {
            setData(result);
            setTimeout(() => {
                setLoaded(true);
                setSelectedRows(result.filter(item => _selectedIds.includes(item.id)));
                setActiveOperation(_actOp);
            }, 500);
        });
    }

    function getFilteredData() {
        let result = [...data];
        if (props.filter) {
            for (let key in props.filter) {
                result = result.filter((item) => {
                    if (props.filter[key].format) {
                        let formattedItem = dayjs(item[key]).format('YYYY-MM-DD');
                        return formattedItem === props.filter[key].format('YYYY-MM-DD');
                    } else {
                        if (props.filter[key].startsWith('"')) {
                            const filterValue = props.filter[key].replace(/"/mg, '').toLowerCase();
                            return !item[key] ? true : item[key].toString().toLowerCase().startsWith(filterValue);
                        } else {
                            return !item[key] ? true : item[key].toString().toLowerCase().includes(props.filter[key].toLowerCase());
                        }
                    }
                });
            }
        }
        if (props.dataFilter) {
            result = props.dataFilter(result);
        }
        return result;
    }

    function renderOperations() {
        if (!props.canEdit) {
            return [];
        }
        function operationClickHandler(operation) {
            if (checkRows()) {
                setActiveOperation(operation);
            }
        }

        let resultRows = [];
        let rowKey = 1;
        let resultCols = [];
        if (props.operationButtons) {
            let operations = props.operationButtons;
            if (props.operationButtons.length > 1) {
                operations = zip(operations[0], operations[1]);
            }
            for (let row of operations) {
                let colKey = 1;
                if (row && row.length > 0) {
                    for (let col of row) {
                        let elem = [];
                        if (col) {
                            elem.push(<Button key={colKey++} type="primary" size={'small'}
                                              style={{marginRight: 10}}
                                              onClick={
                                                  col.handler ? col.handler : () => operationClickHandler(col.id)
                                              }
                            >{col.title}</Button>);
                        }
                        resultCols.push(elem);
                    }
                }
            }
            resultRows.push(<Row key={rowKey++} children={resultCols}/>)
        }
        return resultRows;
    }

    function checkRows() {
        if (!selectedRows || selectedRows.length === 0) {
            NotifyError('Выберите пробирки');
            return false;
        }
        return true;
    }

    function onOperationHide() {
        if (props.onAfterOperation) {
            props.onAfterOperation();
        }
        setActiveOperation(null);
        setLoaded(false);
        const selectedIds = getSelectedRowKeys();
        load().then((result) => {
            setData(result);
            setLoaded(true);
            const newSelectedRows = result.filter(item => selectedIds.includes(item.id));
            setSelectedRows(newSelectedRows);
        });
    }

    function onOperationChange(data) {
        setSelectedRows(data);
    }

    function onSelectRow(rows) {
        setSelectedRows(rows);
        if (props.onSelectionChange) {
            props.onSelectionChange(rows);
        }
    }

    function onChangeExtraFilter() {
        setSelectedRows([]);
    }

    function getSelectedRowKeys() {
        let result = selectedRows.map(item => item.id);
        return result;
    }

    const onChangeTypesFilter = function (values) {
        setFilterTypes(values);
    };

    function labelPrintClick() {
        let ids = getSelectedRowKeys();
        if (ids.length === 0) {
            NotifyError('Выберите пробирки');
            return;
        }
        setLoadLabels(true);
        let url = '/api/portion/print';
        api.postJSON(url, {ids: ids}, {}, true).then(blob => {
            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob,
                {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            a.download = "labels.xlsx";
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
        }).finally(() => {
            setLoadLabels(false);
        });
    }

    return <>
        {props.title && <PageHeader
            title={props.title}
        />}
        <div style={{background: '#fff', padding: '15px 24px', minHeight: 280}}>
            <Form>
                <Row>
                    <Col span={10}>
                        <Form.Item name={'search'} initialValue={props.initialSearchString} style={{marginBottom: 14}}>
                            <Input.Search size={'small'} placeholder="Поиск" onChange={debounce(onSearch, 500)}/>
                        </Form.Item>
                    </Col>
                    <Col span={10} style={{paddingLeft: 10}}>
                        <Form.Item label={'Типы'} style={{marginBottom: 14}}>
                            <Select mode="tags" placeholder="Типы" size={'small'}
                                    onChange={onChangeTypesFilter}>
                                <OptGroup label="Тип">
                                    {types && types.length > 0 && types.map((item, iterator) => {
                                        if (item.type === 0) {
                                            return <Option key={item.id} value={item.name}>{item.name}</Option>;
                                        }
                                    })}
                                </OptGroup>
                                <OptGroup label="Подтип">
                                    {types && types.length > 0 && types.map((item, iterator) => {
                                        if (item.type === 1) {
                                            return <Option key={item.id} value={item.name}>{item.name}</Option>;
                                        }
                                    })}
                                </OptGroup>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4} style={{display: 'flex', justifyContent: 'right'}}>
                        {props.showLabelsBtn && <Button icon={<PrinterOutlined/>} loading={loadLabels} size={'small'}
                                onClick={labelPrintClick}>Этикетки</Button>}
                    </Col>
                </Row>
            </Form>
            {props.extraFilter && <Col span={24} style={{marginBottom: 19}}>
                {props.extraFilter ? props.extraFilter(onChangeExtraFilter, !loaded) : null}
            </Col>}
            <Col span={24} style={{marginBottom: 19}}>
                {renderOperations()}
            </Col>
            <StageData data={getFilteredData()}
                       columns={props.columns}
                       visibleMode={props.visibleMode}
                       onFilter={onFilterHandler}
                       onSelectRow={onSelectRow}
                       loading={!loaded}
                       selectedRows={selectedRows}
                       pagination={props.pagination ? props.pagination : null}
                       onDataTableChange={props.onDataTableChange ? props.onDataTableChange : null}
                       stage={props.stage}
            />
        </div>
        {activeOperation ? getOperations(selectedRows, activeOperation, onOperationHide, onOperationChange,
            props.currentStageId, {...props.options, selectedRowKeys: getSelectedRowKeys(), refresh}) : null}
    </>;

}

export default StageManager;

StageManager.propTypes = {
    currentStageId: PropTypes.number,
    onLoad: PropTypes.func,
    onSelectionChange: PropTypes.func,
    selectedRows: PropTypes.array,
    initialSearchString: PropTypes.string,
    onAfterOperation: PropTypes.func,
    pagination: PropTypes.object,
    stage: PropTypes.string,
    options: PropTypes.object,
    canEdit: PropTypes.bool,
    showLabelsBtn: PropTypes.bool,
}

StageManager.defaultProps = {
    canEdit: true,
    showLabelsBtn: true,
}
